import { CssBaseline } from '@mui/material'
import { useRollbar } from '@rollbar/react'
import { ErrorRender, ThrownErrorBoundary } from '@xylabs/react-common'
import { UserEventsProvider } from '@xylabs/react-pixel'
import { assertEx } from '@xylabs/sdk-js'
import React from 'react'
import { Helmet } from 'react-helmet'

import {
  AuthProvider, FirebaseAppProvider, PixelProvider, SettingsLoader, StorageProvider, UserProvider,
} from '#contexts'
import { getMetaPropertyContent } from '#lib'
import { getDataismUserEvents } from '#user-events'

import { AppThemeBody } from './theme/index.ts'

const gitHash = getMetaPropertyContent('xy:git-hash')

export const AppInner: React.FC = () => {
  const userEvents = getDataismUserEvents(import.meta.env.VITE_MIXPANEL_PUBLIC_PROJECT_TOKEN)
  assertEx(userEvents, () => 'No user Events!!')

  console.log('Dataism.Org Started:', gitHash)

  try {
    globalThis.rollbar = useRollbar()
  } catch {
    console.error('Rollbar not initialized')
  }
  return (
    <ThrownErrorBoundary
      errorComponent={(error, boundaryName) => <ErrorRender error={error} scope={boundaryName} />}
      rollbar={rollbar}
      scope="AppInner"
    >
      <FirebaseAppProvider>
        <StorageProvider>
          <PixelProvider id="dataism.org">
            <UserEventsProvider userEvents={userEvents}>
              <AuthProvider>
                <UserProvider>
                  <SettingsLoader>
                    <Helmet defaultTitle="Dataism.org" titleTemplate="%s" />
                    <CssBaseline />
                    <AppThemeBody />
                  </SettingsLoader>
                </UserProvider>
              </AuthProvider>
            </UserEventsProvider>
          </PixelProvider>
        </StorageProvider>
      </FirebaseAppProvider>
    </ThrownErrorBoundary>
  )
}
