import type { GetTokenOptions } from '@firebase/messaging'
import { getMessaging, getToken } from '@firebase/messaging'

import { app } from '#firebase'

// Retrieve an instance of Firebase Messaging so that it can handle background
// messages.
const messaging = getMessaging(app)

/**
 * Public VAPID key for Web Push Protocol.
 */
const vapidKey = 'BH4hf4mm2K3nDsx6mVNSXSnDMff-Za0czL9f6TE0BvY6f5vp4Em5XfperOEa1aDpKP5koLHQiPm8Pulau5DI7W8'

const GET_TOKEN_OPTIONS: GetTokenOptions = {
  vapidKey,
  // NOTE: Optional custom service worker registration if we want to
  // possibly include their code in our own service worker instead.
  // serviceWorkerRegistration: undefined,
}

export const getBrowserNotificationToken = async () => {
  // Get the token for the user to send them notifications
  return await getToken(messaging, GET_TOKEN_OPTIONS)
}
