import { useTheme } from '@mui/material'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import React from 'react'
import {
  BrowserRouter, Route, Routes,
} from 'react-router-dom'

import { SiteRoutes } from '#brochure'
import { DashboardRoutes } from '#web-app'

import { CaptureUtmParams } from './CaptureUtm.tsx'

export const AppBody: React.FC = () => {
  const theme = useTheme()

  return (
    <BrowserRouter>
      <FlexGrowCol
        width="100vw"
        minHeight="100vh"
        justifyContent="flex-start"
        alignContent="stretch"
        bgcolor={theme.palette.background.default}
        color={theme.palette.text.primary}
      >
        <CaptureUtmParams />
        <Routes>
          <Route path="/dashboard/*" element={<DashboardRoutes />} />
          <Route path="*" element={<SiteRoutes />} />
        </Routes>
      </FlexGrowCol>
    </BrowserRouter>
  )
}
