import { Dashboard, Hiking } from '@mui/icons-material'
import {
  Snackbar,
  SpeedDialAction,
} from '@mui/material'
import { FlexCol } from '@xylabs/react-flexbox'
import React, { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { useUser } from '#contexts'
import { useGetNextJourney } from '#storage'

import { StyledFabBadge } from './Badge.tsx'
import { SpeedDialInner } from './SpeedDialInner.tsx'

export const DataismSpeedDial: React.FC = () => {
  const { user } = useUser()
  const navigate = useNavigate()
  const [nextJourney, nextJourneyError] = useGetNextJourney()

  // More to come but for now it is just the next journey that matters
  const notify = !!nextJourney

  // send algorithm errors to rollbar
  useEffect(() => {
    if (nextJourneyError) {
      globalThis.rollbar?.error('Next Journey Error', nextJourneyError)
    }
  }, [nextJourneyError])

  const actions = useMemo(() => {
    const actions = []
    if (nextJourney) {
      const label = nextJourney.state === 'inProgress' ? 'Continue Journey' : 'Start Journey'
      actions.push({
        icon: <Hiking />, id: 'journey', label,
      })
    }
    if (user) {
      actions.push({
        icon: <Dashboard />, id: 'dashboard', label: 'Dashboard',
      })
    }
    return actions
  }, [user, nextJourney])

  const handleActionClick = (id: string) => {
    switch (id) {
      case 'dashboard': {
        navigate('/dashboard')
        break
      }
      case 'journey': {
        navigate(`/dashboard/journey/${nextJourney?.journey.slug}`)
        break
      }
    }
  }

  return (
    <>
      {/* TODO - bring back once notifications are implemented */}
      {/* <NotificationsDialog open={openNotifyDialog} onClose={() => setOpenNotifyDialog(false)} nextJourney={nextJourney} /> */}
      <Snackbar open={!!nextJourneyError} message={nextJourneyError?.message} autoHideDuration={6000} />
      {user
        ? (
            <StyledFabBadge badgeContent=" " color="error" invisible={!notify}>
              <FlexCol>
                <SpeedDialInner ariaLabel="SpeedDial">
                  {actions.map(action => (
                    <SpeedDialAction
                      key={action.id}
                      icon={action.icon}
                      tooltipTitle={action.label}
                      tooltipOpen
                      onClick={() => handleActionClick(action.id)}
                    />
                  ))}
                </SpeedDialInner>
              </FlexCol>
            </StyledFabBadge>
          )
        : null}
    </>
  )
}
