import type { Payload } from '@xyo-network/payload-model'
import { isPayloadOfSchemaTypeWithMeta } from '@xyo-network/payload-model'

import type { Sluggish } from '../../Sluggish.ts'

export const TaskResourceSchema = 'org.dataism.journey.task.resource' as const
export type TaskResourceSchema = typeof TaskResourceSchema

export interface TaskResourceFields extends Sluggish {
  title: string
  url: string
}

export type TaskResource = Payload<TaskResourceFields, TaskResourceSchema>
export const isTaskResource = isPayloadOfSchemaTypeWithMeta<TaskResource>(TaskResourceSchema)
