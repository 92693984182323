import type { Auth } from '@firebase/auth'
import { useEffect } from 'react'

import { loginWithGoogleOneTap } from '#users'

// Define the Google Credential Response interface
interface GoogleCredentialResponse {
  credential: string
}

declare global {
  var google: {
    accounts: {
      id: {
        initialize: (options: {
          callback: (response: GoogleCredentialResponse) => void
          client_id: string
        }) => void
        prompt: (options?: object) => void
      }
    }
  }
}

export interface GoogleOneTapLoginProps {
  auth: Auth
  clientId: string
  enabled?: boolean
}

export const GoogleOneTapLogin: React.FC<GoogleOneTapLoginProps> = ({
  auth, clientId, enabled,
}) => {
  useEffect(() => {
    if (enabled) {
      const initializeGoogleOneTap = () => {
      // Ensure google is available on the window object
        if (globalThis.google) {
          globalThis.google.accounts.id.initialize({
            client_id: clientId,
            callback: handleCredentialResponse,
          })

          globalThis.google.accounts.id.prompt() // Display the One Tap prompt
        }
      }

      const handleCredentialResponse = (response: GoogleCredentialResponse) => {
        console.log('handleCredentialResponse: ' + response.credential)

        loginWithGoogleOneTap(auth, response.credential).catch((error) => {
          console.error('Error logging in with Google One Tap:', error)
        })
      }

      // Load Google Identity Services script dynamically, if not already included in index.html
      if (globalThis.google) {
        initializeGoogleOneTap()
      } else {
        const script = document.createElement('script')
        script.src = 'https://accounts.google.com/gsi/client'
        script.async = true
        script.defer = true
        script.addEventListener('load', initializeGoogleOneTap)
        document.body.append(script)
        return () => {
          script.removeEventListener('load', initializeGoogleOneTap)
        }
      }
    }
  }, [enabled])

  return null // This component doesn't need to render anything visible
}
