/* eslint-disable @stylistic/max-len */
import { type QuizRequirement, QuizRequirementSchema } from '#models'

import { GeneralInternetQuizSlug, GenericTaskSlug } from './Slug.ts'

export const GeneralInternetQuizData: QuizRequirement = {
  slug: GeneralInternetQuizSlug,
  type: 'quiz',
  task: GenericTaskSlug,
  schema: QuizRequirementSchema,
  questions: [
    {
      questionText: 'Which logo represents "Google?',
      type: 'multiple-choice',
      outcomes: [{ thresholds: { min: { google: 1 } } }],
      options: [
        {
          slug: 'slug-google',
          value: { google: 1 },
          name: 'Google',
          image: 'https://www.svgrepo.com/show/303108/google-icon-logo.svg',
        },
        {
          slug: 'slug-chrome',
          value: { chrome: 0 },
          name: 'Chrome',
          image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Google_Chrome_icon_%28February_2022%29.svg/480px-Google_Chrome_icon_%28February_2022%29.svg.png',
        },
        {
          slug: 'slug-facebook',
          value: { facebook: 0 },
          name: 'Facebook',
          image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQKrFhY-ljA-u7J5IMWeTv8zmpBx4PP9nQMw&s',
        },
        {
          slug: 'slug-last-pass',
          value: { lastPass: 0 },
          name: 'LastPass',
          image: 'https://cdn.icon-icons.com/icons2/2699/PNG/512/lastpass_tile_logo_icon_168330.png',
        },
      ],
    },
  ],
}
