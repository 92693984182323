import React, { lazy, Suspense } from 'react'

import type { ResultDataProps } from './UserPersonaScoreChart.tsx'

const LazyUserPersonaScoreChart = lazy(() => import('./UserPersonaScoreChart.tsx'))
export const UserPersonaScoreChart: React.FC<ResultDataProps> = (props) => {
  return (
    <Suspense fallback={<div />}>
      <LazyUserPersonaScoreChart {...props} />
    </Suspense>
  )
}
