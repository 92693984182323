import {
  type JourneyTask, JourneyTaskSchema, TaskResourceSchema,
} from '#models'

import { OneHundredDatum } from '../shared/index.ts'
import { TwoFactorAuthenticationBasicsBadge } from './Badge.ts'
import { TwoFactorAuthenticationBasicsQuiz } from './Data.ts'
import {
  TwoFactorAuthenticationBasicsQuizTaskSlug,
  TwoFactorAuthenticationBasicsReadingTaskSlug,
} from './Slugs.ts'
import { TwoFactorAuthenticationBasicsValidation } from './Validation.ts'

export const TwoFactorAuthenticationBasicsReadingTaskData: JourneyTask = {
  description: 'Learn about 2-Factor Authentication First',
  metadata: ['reading'],
  resource: [{
    title: 'Google Authenticator: Enhance Your Online Security with Dataism',
    url: 'https://dataism.org/blog/google-authenticator-enhance-your-online-security-with-dataism?from_quiz=1',
    schema: TaskResourceSchema,
    slug: 'slug-two-factor-authentication-basics-reading',
  }],
  schema: JourneyTaskSchema,
  slug: TwoFactorAuthenticationBasicsReadingTaskSlug,
  timeToComplete: (60 * 2) * 1000,
  title: 'Read to Learn',
  taskSet: {
    reward: [],
    validatedRequirements: [],
  },
}

export const TwoFactorAuthenticationBasicsQuizTaskData: JourneyTask = {
  // eslint-disable-next-line @stylistic/max-len
  description: 'This quiz tests your understanding of Google Authenticator and its role in enhancing online security. It covers key concepts like two-factor authentication, Dataism, and best practices for safeguarding your digital accounts.',
  metadata: ['quiz'],
  resource: [],
  schema: JourneyTaskSchema,
  slug: TwoFactorAuthenticationBasicsQuizTaskSlug,
  timeToComplete: (60 * 2) * 1000,
  title: 'A Google Authenticator Quiz',
  taskSet: {
    reward: [TwoFactorAuthenticationBasicsBadge, OneHundredDatum],
    validatedRequirements: [{ requirement: TwoFactorAuthenticationBasicsQuiz, validation: TwoFactorAuthenticationBasicsValidation }],
  },
}
