import React from 'react'

import { DataismBasePage } from '#chrome'

import { ToolsHero } from './Hero.tsx'

export const ToolsPage: React.FC = () => {
  return (
    <DataismBasePage title="Ratings">
      <ToolsHero />
    </DataismBasePage>
  )
}
