import { isPayloadOfSchemaTypeWithMeta, type Payload } from '@xyo-network/payload-model'

import type { Slug, Sluggish } from '../Sluggish.ts'
import type { JourneyTask, JourneyTaskMetadata } from './task/index.ts'

export const JourneySchema = 'org.dataism.journey' as const
export type JourneySchema = typeof JourneySchema

export interface JourneyFields extends Sluggish {
  description: string
  heroImage: Slug
  releaseDate?: number
  taskTypes: JourneyTaskMetadata[]
  tasks: JourneyTask[]
  timeToComplete: number
  title: string
  topics: string[]
  version?: number
}

export type Journey = Payload<JourneyFields, JourneySchema>
export const isJourney = isPayloadOfSchemaTypeWithMeta<Journey>(JourneySchema)
