import { AxiosJson } from '@xylabs/axios'

export const iterableBaseUrl = 'https://api.iterable.com/api'

export const iterableEventsUrl = `${iterableBaseUrl}/events`
export const iterableTrackUrl = `${iterableEventsUrl}/track`

export const iterableUsersUrl = `${iterableBaseUrl}/users`
export const iterableUsersUpdateUrl = `${iterableUsersUrl}/update`

export const IterableAxios = new AxiosJson({ headers: { 'Api-Key': import.meta.env.VITE_ITERABLE_API_KEY } })

export const IterableEvents = {
  accountLogin: 'dataismAccountLogin',
  waitlistSignup: 'dataismWaitlistSignup',
}
