import React from 'react'

import { DataismBasePage } from '#chrome'

import { PeopleHero } from './Hero.tsx'

export const PeoplePage: React.FC = () => {
  return (
    <DataismBasePage title="People">
      <PeopleHero />
    </DataismBasePage>
  )
}
