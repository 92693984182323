import {
  type QuizTaskValidation, QuizTaskValidationSchema, type Slug,
} from '#models'

export const DataistPersonaQuizValidationSlug: Slug = 'slug-dataist-persona-quiz-validation'

export const DataistPersonaQuizValidation: QuizTaskValidation = {
  outcomes: [{ thresholds: { min: { answered: 1 } } }],
  type: 'quiz',
  schema: QuizTaskValidationSchema,
  slug: DataistPersonaQuizValidationSlug,
}
