import type { Auth } from '@firebase/auth'
import { useContext } from 'react'

import { AuthContext } from './Context.ts'

/**
 * A hook to use the Auth instance from within the AuthContext
 * @returns The Auth instance
 */
export const useAuth = (): Auth => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
