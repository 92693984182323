import { connectFirestoreEmulator, getFirestore } from '@firebase/firestore'

import { app } from './app.ts'

const firestore = getFirestore(app)

// If we're running on localhost, connect to the Firestore emulator
if (import.meta.env.VITE_HOST_URL?.includes('localhost')) {
  connectFirestoreEmulator(firestore, 'localhost', 8080)
}

export { firestore }
