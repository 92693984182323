import type { BusyBoxProps } from '@xylabs/react-flexbox'
import { FlexCol } from '@xylabs/react-flexbox'
import React from 'react'

import {
  ChangingRadarChart, DataismBasicHero, type PersonaRadarChartProps,
} from '#components'

export const HomeHero: React.FC<BusyBoxProps> = ({ ...props }) => {
  const initialResultData: PersonaRadarChartProps = {
    initialResultData: [
      {
        fullMark: 20, persona: 'Aegis', value: 10,
      },
      {
        fullMark: 20, persona: 'Cass', value: 8,
      },
      {
        fullMark: 20, persona: 'Echo', value: 12,
      },
      {
        fullMark: 20, persona: 'Lux', value: 6,
      },
      {
        fullMark: 20, persona: 'Myca', value: 15,
      },
      {
        fullMark: 20, persona: 'Nova', value: 9,
      },
      {
        fullMark: 20, persona: 'Rook', value: 13,
      },
      {
        fullMark: 20, persona: 'Vex', value: 7,
      },
    ],
  }

  return (
    <FlexCol
      alignItems="stretch"
      sx={{
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: { xs: '600px', md: '700px' },
      }}
      {...props}
    >
      <DataismBasicHero
        title="Reclaiming the Human Side of Data"
        desc="Your data is not just numbers. It's a part of you.
        Learn how to control, protect, and make the most of your data while staying true to who you are."
        button1To="/start"
        button1Text="Get Started"
        button2To="#learn-more"
        button2Text="Learn More"
        heroComponent={(
          <ChangingRadarChart initialResultData={initialResultData.initialResultData} />
        )}
      />
    </FlexCol>
  )
}
