import {
  Container,
  Stack, Typography,
} from '@mui/material'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React from 'react'

import { dataismPillarsData } from '#data'

export const Breakdown: React.FC = () => {
  return (
    <SectionSpacingRow>
      <Container>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
          gap={4}
        >
          {dataismPillarsData.map(pillar => (
            <SectionSpacingRow key={pillar.title}>
              <Stack flexDirection="column" alignItems="flex-start" gap={2}>
                <Typography variant="body1">{pillar.title}</Typography>
                <Typography variant="h5">
                  {pillar.desc}
                </Typography>
              </Stack>
            </SectionSpacingRow>
          ))}
        </Stack>
      </Container>
    </SectionSpacingRow>
  )
}
