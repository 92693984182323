import type { FirebaseError } from '@firebase/app'
import type {
  Auth, AuthProvider, UserCredential,
} from '@firebase/auth'
import {
  FacebookAuthProvider, GithubAuthProvider,
  GoogleAuthProvider, OAuthProvider,
  signInWithRedirect, TwitterAuthProvider,
} from '@firebase/auth'

/**
 * Generic login function for Firebase authentication providers
 * @param auth The Firebase Auth instance
 * @param provider The provider to use for authentication
 */
export const loginWithProvider = async (auth: Auth, provider: AuthProvider) => {
  try {
    // TODO: Consider whether to alert if already logged in
    if (!auth.currentUser) {
      await signInWithRedirect(auth, provider)
    }
  } catch (e) {
    handleAuthError(auth, provider, e)
  }
}

export const getCredentialFromRedirectResult = (result: UserCredential) => {
  // Extract credential based on provider type using switch statement
  let credential
  switch (result.providerId) {
    case FacebookAuthProvider.PROVIDER_ID: {
      credential = FacebookAuthProvider.credentialFromResult(result)
      break
    }
    case GithubAuthProvider.PROVIDER_ID: {
      credential = GithubAuthProvider.credentialFromResult(result)
      break
    }
    case GoogleAuthProvider.PROVIDER_ID: {
      credential = GoogleAuthProvider.credentialFromResult(result)
      break
    }
    case TwitterAuthProvider.PROVIDER_ID: {
      credential = TwitterAuthProvider.credentialFromResult(result)
      break
    }
    default: {
      console.warn('Unsupported provider for credential extraction')
      credential = OAuthProvider.credentialFromResult(result)
    }
  }
  console.log(`credentialFromResult: ${credential}`)
}

const handleAuthError = (auth: Auth, provider: AuthProvider, e: unknown) => {
  // Handle different error cases
  const error = e as FirebaseError
  const errorCode = error?.code
  const errorMessage = error?.message
  if (!errorCode || !errorMessage) {
    console.error('Unknown error', error)
  } else {
    console.error(errorCode, errorMessage)
    // Extract credential from error based on provider type using switch statement
    let credential
    switch (true) {
      case provider instanceof FacebookAuthProvider: {
        credential = FacebookAuthProvider.credentialFromError(error)
        break
      }
      case provider instanceof GithubAuthProvider: {
        credential = GithubAuthProvider.credentialFromError(error)
        break
      }
      case provider instanceof GoogleAuthProvider: {
        credential = GoogleAuthProvider.credentialFromError(error)
        break
      }
      case provider instanceof TwitterAuthProvider: {
        credential = TwitterAuthProvider.credentialFromError(error)
        break
      }
      default: {
        console.warn('Unsupported provider for credential extraction')
      }
    }
    console.log(`credentialFromError: ${credential}`)
  }
}
