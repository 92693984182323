import type { CommonFields } from '@xylabs/pixel'

import type { CustomProperties } from './CustomProperties.ts'
import type { Events } from './DataismEventHandler.ts'
import { DataismEventHandler } from './DataismEventHandler.ts'
import type { DataismEventHandlerInterface } from './DataismEventHandlerInterface.ts'
import { GoogleEvents } from './Google/index.ts'

export class GoogleEventHandler<T extends CommonFields = CommonFields> extends DataismEventHandler<T> implements DataismEventHandlerInterface<T> {
  protected googleEvents = new GoogleEvents<CustomProperties>()

  override get events() {
    return this.googleEvents as Events<T>
  }
}
