import type { Payload } from '@xyo-network/payload-model'
import { isPayloadOfSchemaType, isPayloadOfSchemaTypeWithMeta } from '@xyo-network/payload-model'

import type { Sluggish } from '../../../Sluggish.ts'
import type { TaskRequirementBase } from './Base.ts'

export const QuizRequirementSchema = 'org.dataism.journey.task.requirement.quiz' as const
export type QuizRequirementSchema = typeof QuizRequirementSchema

export interface QuizOutcomeThresholds {
  max?: Record<string, number>
  min?: Record<string, number>
}

export interface QuizOutcomeFields {
  eagerlyValidate?: boolean
  image?: string
  thresholds: QuizOutcomeThresholds
}

export interface QuizOptionFields extends Sluggish {
  image?: string
  name?: string
  value?: Record<string, number>
}
export interface QuizQuestionFields {
  image?: string
  options: QuizOptionFields[]
  outcomes?: QuizOutcomeFields[]
  questionText: string
  type: 'multiple-choice' | 'true-false' | 'fill-in-the-blank' | 'comprehension'
}

export interface QuizRequirementFields extends TaskRequirementBase {
  questions: QuizQuestionFields[]
  type: 'quiz'
}

export type QuizRequirement = Payload<QuizRequirementFields, QuizRequirementSchema>
export const isQuizRequirementWithMeta = isPayloadOfSchemaTypeWithMeta<QuizRequirement>(QuizRequirementSchema)
export const isQuizRequirement = isPayloadOfSchemaType<QuizRequirement>(QuizRequirementSchema)
