import React from 'react'
import type { RouteProps } from 'react-router-dom'
import { Route, Routes } from 'react-router-dom'

import { Four04Page } from '../resources/index.ts'
import { RequestEarlyAccessPage } from './pages/index.ts'

export const EarlyAccessRoutes: React.FC<RouteProps> = (props) => {
  return (
    <Routes {...props}>
      <Route element={<RequestEarlyAccessPage />} path="/" />
      <Route path="*" element={<Four04Page />} />
    </Routes>
  )
}
