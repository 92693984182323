import type { CommonFields } from '@xylabs/pixel'

import type { CustomProperties } from './CustomProperties.ts'
import type { Events } from './DataismEventHandler.ts'
import { DataismEventHandler } from './DataismEventHandler.ts'
import type { DataismEventHandlerInterface } from './DataismEventHandlerInterface.ts'
import { FacebookEvents } from './Facebook/index.ts'

export class FacebookEventHandler<TData extends CommonFields = CommonFields> extends DataismEventHandler<TData> implements DataismEventHandlerInterface<TData> {
  protected facebookEvents = new FacebookEvents<CustomProperties>()

  override get events() {
    return this.facebookEvents as Events<TData>
  }
}
