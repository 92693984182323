import type { ReactElement } from 'react'
import React, { useMemo, useState } from 'react'

import { SettingsContext } from './Context.ts'

interface AppSettingsLoaderProps {
  children?: ReactElement[] | ReactElement | undefined
}

const LocalStoragePrefix = 'AppSetting|'

enum Settings {
  DarkMode = 'DarkMode',
  DeveloperMode = 'DeveloperMode',
}

export const SettingsLoader: React.FC<AppSettingsLoaderProps> = (props) => {
  const currentState = localStorage.getItem(`${LocalStoragePrefix}${Settings.DarkMode}`)
  const [darkMode, setDarkMode] = useState<boolean>(currentState === 'true' ? true : currentState === 'false' ? false : true)

  const [developerMode, setDeveloperMode] = useState<boolean>(localStorage.getItem(`${LocalStoragePrefix}${Settings.DeveloperMode}`) === 'true')

  const value = useMemo(() => {
    const enableDarkMode = (enabled: boolean) => {
      localStorage.setItem(`${LocalStoragePrefix}${Settings.DarkMode}`, enabled ? 'true' : 'false')
      setDarkMode(enabled)
    }

    const enableDeveloperMode = (enabled: boolean) => {
      localStorage.setItem(`${LocalStoragePrefix}${Settings.DeveloperMode}`, enabled ? 'true' : 'false')
      setDeveloperMode(enabled)
    }

    return {
      darkMode,
      developerMode,
      enableDarkMode,
      enableDeveloperMode,
    }
  }, [darkMode, developerMode])

  return (
    <SettingsContext.Provider
      value={value}
      {...props}
    />
  )
}
