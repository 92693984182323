import { IterableAxios, iterableBaseUrl } from './IterableAxios.ts'
import type { IterableRegisterBrowserTokenEmailWithEmailPostBody } from './IterableRegisterBrowserTokenPostBody.ts'

const iterableRegisterBrowserTokenUrl = `${iterableBaseUrl}$/users/registerBrowserToken`

/**
 * Registers a browser token with Iterable for a given user via the user's email address.
 * @param browserToken The browser token to register
 * @param email The email of the user to register the browser token for
 */
export const registerBrowserToken = async (browserToken: string, email: string) => {
  // If permission is granted, should fetch the user's browser token and then pass it to
  // Iterable using the POST /api/users/registerBrowserToken API endpoint.
  const data: IterableRegisterBrowserTokenEmailWithEmailPostBody = { browserToken, email }
  const result = await IterableAxios.post(iterableRegisterBrowserTokenUrl, data)
  if (result.status === 200) {
    // TODO: Store success in state so we don't prompt again
    return true
  }
  return false
}
