import type { Auth } from '@firebase/auth'
import { FacebookAuthProvider } from '@firebase/auth'

import { linkWithProvider, loginWithProvider } from '../lib/index.ts'

/**
 * Signs in a user with their Facebook account
 * @param auth The Firebase Auth instance
 */
export const loginWithFacebook = (auth: Auth) => loginWithProvider(auth, new FacebookAuthProvider())

/**
 * Links a user with their Facebook account
 * @param auth The Firebase Auth instance
 */
export const linkWithFacebook = (auth: Auth) => linkWithProvider(auth, new FacebookAuthProvider())
