import { type JourneyTask, JourneyTaskSchema } from '#models'

import { OneHundredDatum } from '../shared/index.ts'
import { LogoMemoryBadge } from './LogoMemoryBadge.ts'
import { LogoMemoryQuizData } from './LogoMemoryQuizData.ts'
import { LogoMemoryValidation } from './LogoMemoryValidation.ts'
import { LogoMemoryTaskSlug } from './Slugs.ts'

export const TaskData: JourneyTask = {
  description: 'Generic Task Description',
  metadata: ['quiz'],
  resource: [],
  schema: JourneyTaskSchema,
  slug: LogoMemoryTaskSlug,
  timeToComplete: (60 * 2) * 1000,
  title: 'Generic Task Title',
  taskSet: {
    reward: [LogoMemoryBadge, OneHundredDatum],
    validatedRequirements: [{ requirement: LogoMemoryQuizData, validation: LogoMemoryValidation }],
  },
}
