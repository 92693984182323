import { Container, Divider } from '@mui/material'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import { SectionSpacingRow } from '@xyo-network/react-shared'
import React from 'react'

import { DataismBasePage } from '#chrome'

import { PrivacyPolicyPages } from '../../img/index.ts'
import { PrivacyHero } from './Hero.tsx'

export const PrivacyPage: React.FC = () => {
  return (
    <DataismBasePage name="Privacy">
      <PrivacyHero />
      <Divider />
      <SectionSpacingRow>
        <Container>
          <FlexGrowCol>
            {PrivacyPolicyPages.map((src, index) => (
              <img key={src.toString()} src={src} alt={`Privacy Policy Page ${index + 1}`} width="100%" />
            ))}
          </FlexGrowCol>
        </Container>
      </SectionSpacingRow>
    </DataismBasePage>
  )
}
