import { QuestionAnswerRounded as QuestionAnswerRoundedIcon } from '@mui/icons-material'
import { BasicHero } from '@xyo-network/react-shared'
import React from 'react'

import { DataismBasePage } from '#chrome'

export const Four04Page: React.FC = () => {
  return (
    <DataismBasePage title="404">
      <BasicHero
        title="404"
        desc="Whoops! It looks like that page is no longer available."
        button1Text="Take me home!"
        button1To="/"
        subLinkIcon={<QuestionAnswerRoundedIcon />}
        subLinkPath="https://support.xy.company/hc/en-us/requests/new"
        subLinkText1="Think this is a bug?"
        subLinkText2="Report it to our team here."
      />
    </DataismBasePage>
  )
}
