import { ArrowForwardRounded, Lock } from '@mui/icons-material'
import type { CardProps } from '@mui/material'
import {
  Box,
  Card, CardContent, Typography, useTheme,
} from '@mui/material'
import { FlexGrowCol, FlexRow } from '@xylabs/react-flexbox'
import React, { useEffect, useState } from 'react'

import type { Persona } from '#data'

export interface PersonaCardProps extends CardProps {
  extraContent?: React.ReactNode
  icon?: React.ReactNode
  locked?: boolean
  persona: Persona
  subtitle?: string
  summary?: string
  title: string
}

export const PersonaCard: React.FC<PersonaCardProps> = ({
  title,
  subtitle,
  summary,
  locked,
  icon,
  extraContent,
  persona,
  ...props
}) => {
  const theme = useTheme()
  const [rotationAngle, setRotationAngle] = useState<number>(0)
  const [hovered, setHovered] = useState<boolean>(false)

  useEffect(() => {
    if (hovered) {
      const interval = setInterval(() => {
        setRotationAngle(prev => (prev + 0.4) % 360)
      }, 50)
      return () => {
        clearInterval(interval)
      }
    }
  }, [hovered])

  const handleMouseEnter = () => setHovered(true)
  const handleMouseLeave = () => setHovered(false)

  const iconStyles = {
    color: hovered ? theme.palette.personas[persona.name].dark : 'inherit',
    transform: `rotate(${rotationAngle}deg)`,
    transition: 'transform 0.1s linear',
  }

  const textColor = hovered ? theme.palette.personas[persona.name].contrastText : 'inherit'
  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  return (
    <Card
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...props}
      sx={{
        backgroundColor: hovered ? theme.palette.personas[persona.name].main : theme.palette.background.paper,
        cursor: 'pointer',
        textDecoration: 'none',
        overflow: 'hidden',
        ...props.sx,
      }}
    >
      <CardContent sx={{
        height: '100%', overflow: 'hidden', color: textColor, position: 'relative',
      }}
      >
        <FlexGrowCol sx={{
          height: '100%', alignItems: 'flex-start', justifyContent: 'space-between',
        }}
        >
          <Box position="relative"><Box overflow="hid" style={iconStyles}>{icon}</Box></Box>
          <FlexGrowCol alignItems="flex-start">
            <Typography variant="h6" fontWeight="bold" textTransform="capitalize" color={textColor}>
              {title ?? capitalizeFirstLetter(persona.name)}
            </Typography>
            <Typography variant="body1" gutterBottom color={textColor}>
              {subtitle ?? persona.subname}
            </Typography>
            <Typography variant="subtitle2" gutterBottom paddingBottom={1} color={textColor}>
              {summary ?? persona.summary}
            </Typography>
          </FlexGrowCol>
          {extraContent ?? (
            <FlexRow gap={1} alignItems="center" sx={{ color: textColor }}>
              <Typography variant="body2">Learn More</Typography>
              {' '}
              <ArrowForwardRounded fontSize="small" />
            </FlexRow>
          )}
        </FlexGrowCol>
        {locked
          ? (
              <Box
                position="absolute"
                sx={{ left: 16, top: 16 }}
              >
                <FlexRow gap={1}>
                  <Lock />
                  <Typography variant="caption" color="textSecondary">Requires Persona Journey</Typography>
                </FlexRow>
              </Box>
            )
          : null}
      </CardContent>
    </Card>
  )
}
