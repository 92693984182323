import {
  Container, Grid2,
  Typography,
} from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { PersonaCard } from '#components'
import type { PersonaName } from '#data'
import { dataistPersonaData, personaIconsData } from '#data'

export const DataistPersonas: React.FC = () => {
  const navigate = useNavigate()
  return (
    <Container
      id="personas"
      sx={{
        display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: 2, marginBottom: 2,
      }}
    >
      <Typography variant="body1" gutterBottom>Dataist Personas</Typography>
      <Grid2 container spacing={0.5}>
        {dataistPersonaData.map(persona => (
          <Grid2
            size={{
              xs: 12, sm: 6, md: 3,
            }}
            key={persona.name}
          >
            <PersonaCard
              persona={persona}
              title={persona.name}
              icon={personaIconsData[persona.name as PersonaName].icon}
              onClick={() => { navigate(`/persona/${persona.name}`) }}
            />
          </Grid2>
        ))}
      </Grid2>
    </Container>
  )
}
