import { type QuizRequirement, QuizRequirementSchema } from '#models'

import { TwoFactorAuthenticationBasicsQuizSlug, TwoFactorAuthenticationBasicsQuizTaskSlug } from './Slugs.ts'

export const TwoFactorAuthenticationBasicsQuiz: QuizRequirement = {
  title: '2-Factor Authentication Basics',
  slug: TwoFactorAuthenticationBasicsQuizSlug,
  task: TwoFactorAuthenticationBasicsQuizTaskSlug,
  type: 'quiz',
  schema: QuizRequirementSchema,
  questions: [
    {
      questionText: 'What is the primary purpose of Google Authenticator?',
      type: 'multiple-choice',
      options: [
        {
          name: 'To create a secure online password', slug: 'slug-create-password', value: { correct: 0 },
        },
        {
          name: 'To generate a one-time password to secure online accounts', slug: 'slug-generate-otp', value: { correct: 1 },
        },
        {
          name: 'To monitor online activity', slug: 'slug-monitor-activity', value: { correct: 0 },
        },
        {
          name: 'To store personal data securely', slug: 'slug-store-data-securely', value: { correct: 0 },
        },
      ],
    },
    {
      questionText: 'What type of authentication does Google Authenticator provide?',
      type: 'multiple-choice',
      options: [
        {
          name: 'Single-factor authentication (SFA)', slug: 'slug-sfa', value: { correct: 0 },
        },
        {
          name: 'Biometric authentication', slug: 'slug-biometric', value: { correct: 0 },
        },
        {
          name: 'Two-factor authentication (2FA)', slug: 'slug-2fa', value: { correct: 1 },
        },
        {
          name: 'Identity-based authentication', slug: 'slug-identity-based', value: { correct: 0 },
        },
      ],
    },
    {
      questionText: 'How does Google Authenticator generate its codes?',
      type: 'multiple-choice',
      options: [
        {
          name: 'Through an internet connection', slug: 'slug-internet-connection', value: { correct: 0 },
        },
        {
          name: 'Through a mobile network', slug: 'slug-mobile-network', value: { correct: 0 },
        },
        {
          name: 'Locally on the device without network connection', slug: 'slug-local-device', value: { correct: 1 },
        },
        {
          name: 'By sending a text message', slug: 'slug-text-message', value: { correct: 0 },
        },
      ],
    },
    {
      questionText: 'Which of the following best describes the concept of Dataism as it relates to Google Authenticator?',
      type: 'multiple-choice',
      options: [
        {
          // eslint-disable-next-line @stylistic/max-len
          name: 'Personal data is controlled by individuals rather than being vulnerable to unauthorized access.', slug: 'slug-control-data', value: { correct: 1 },
        },
        {
          name: 'Data should be accessible to everyone.', slug: 'slug-accessible-data', value: { correct: 0 },
        },
        {
          name: 'All online information should be encrypted.', slug: 'slug-encrypt-data', value: { correct: 0 },
        },
        {
          name: 'Data should be stored in a single, secure location.', slug: 'slug-single-storage', value: { correct: 0 },
        },
      ],
    },
    {
      questionText: 'Why does Google Authenticator matter in today’s digital world?',
      type: 'multiple-choice',
      options: [
        {
          name: 'It increases productivity.', slug: 'slug-productivity', value: { correct: 0 },
        },
        {
          name: 'It helps secure personal data against unauthorized access.', slug: 'slug-secure-data', value: { correct: 1 },
        },
        {
          name: 'It allows users to store data in the cloud.', slug: 'slug-cloud-storage', value: { correct: 0 },
        },
        {
          name: 'It speeds up online logins.', slug: 'slug-speed-logins', value: { correct: 0 },
        },
      ],
    },
    {
      questionText: 'How can a user set up Google Authenticator for an account?',
      type: 'multiple-choice',
      options: [
        {
          name: 'By answering security questions', slug: 'slug-security-questions', value: { correct: 0 },
        },
        {
          name: 'By scanning a QR code', slug: 'slug-scan-qr', value: { correct: 1 },
        },
        {
          name: 'By entering a PIN code', slug: 'slug-enter-pin', value: { correct: 0 },
        },
        {
          name: 'By creating a username', slug: 'slug-create-username', value: { correct: 0 },
        },
      ],
    },
  ],
}
