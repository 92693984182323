import type { Payload } from '@xyo-network/payload-model'
import { isPayloadOfSchemaType, isPayloadOfSchemaTypeWithMeta } from '@xyo-network/payload-model'

import type { QuizOutcomeFields } from '../requirement/index.ts'
import type { TaskValidationBase } from './Base.ts'

export const QuizTaskValidationSchema = 'org.dataism.journey.task.validation.quiz' as const
export type QuizTaskValidationSchema = typeof QuizTaskValidationSchema

export interface QuizTaskValidationFields extends TaskValidationBase {
  outcomes?: QuizOutcomeFields[]
  type: 'quiz'
}

export type QuizTaskValidation = Payload<QuizTaskValidationFields, QuizTaskValidationSchema>
export const isQuizTaskValidationWithMeta = isPayloadOfSchemaTypeWithMeta<QuizTaskValidation>(QuizTaskValidationSchema)
export const isQuizTaskValidation = isPayloadOfSchemaType<QuizTaskValidation>(QuizTaskValidationSchema)
