import type { ReactElement } from 'react'
import React from 'react'

import { auth } from '#firebase'

import { AuthContext } from './Context.ts'

interface AuthProviderProps {
  children?: ReactElement[] | ReactElement | undefined
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  )
}
