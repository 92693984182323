import React from 'react'

import { DataismBasicHero } from '#components'

export const PrivacyHero: React.FC = () => {
  return (
    <DataismBasicHero
      title="Privacy"
      desc="We are a product of XY Labs. Please see our Privacy Terms below."
      overflow="hidden"
    />
  )
}
