import { initializeApp } from '@firebase/app'

// NOTE: This is OK to include with the site as it's standard Firebase
// practice to include these config values in the client-side code
const firebaseConfig = {
  apiKey: 'AIzaSyAoy51aTcNEgq38I7w7gqZpWNPK4moCgRA',
  appId: '1:108377663731:web:4de3c50c2a300b755b6f86',
  authDomain: new URL(import.meta.env.VITE_HOST_URL).host,
  measurementId: 'G-3RFG37B02F',
  messagingSenderId: '108377663731',
  projectId: 'dataism-27ba0',
  storageBucket: 'dataism-27ba0.appspot.com',
}

const app = initializeApp(firebaseConfig)

export { app }
