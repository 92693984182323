import type { Payload } from '@xyo-network/payload-model'
import { isPayloadOfSchemaTypeWithMeta } from '@xyo-network/payload-model'

import type { QuizOptionFields, QuizOutcomeFields } from '../../../../../journey/index.ts'
import type { TaskValidationResultSetBase } from './Base.ts'

export const QuizTaskValidationResultSchema = 'org.dataism.journey.task.validation.quiz.result'
export type QuizTaskValidationResultSchema = typeof QuizTaskValidationResultSchema

export interface QuizTaskValidationResultFields extends TaskValidationResultSetBase {
  // The end time of the quiz
  endTime: number
  // The thresholds for each outcome that was passed
  outcomes?: QuizOutcomeFields[]
  // Save the quiz options that were selected
  results: QuizOptionFields[]
  // The start time of the quiz
  startTime: number
}

export type QuizTaskValidationResult = Payload<QuizTaskValidationResultFields, QuizTaskValidationResultSchema>
export const isQuizTaskValidationResult = isPayloadOfSchemaTypeWithMeta<QuizTaskValidationResult>(QuizTaskValidationResultSchema)
