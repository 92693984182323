import type {
  CommonFields,
  FunnelStartedFields, TestStartedFields, UserClickFields, ViewContentFields,
} from '@xylabs/pixel'
import { UserEventHandler } from '@xylabs/pixel'
import type { Mixpanel } from 'mixpanel-browser'

import { DataismEventHandler } from './DataismEventHandler.ts'
import type {
  AccountCreatedFields, AccountLoginFields,
  AccountLogoutFields,
  AffiliateClickFields,
  DataismEventHandlerInterface, ErrorFields, JourneyCompletedFields,
  JourneyProgressFields, JourneyStartedFields,
  QuizCompleteFields,
  ReferralSentFields,
  RewardGrantedFields,
  TaskCompletedFields, TaskProgressFields, TaskStartedFields,
} from './DataismEventHandlerInterface.ts'
import { FacebookEventHandler } from './FacebookEventHandler.ts'
import { GoogleEventHandler } from './GoogleEventHandler.ts'
import { MixpanelEventHandler } from './MixpanelEventHandler.ts'

export class DataismUserEvents<TData extends CommonFields = CommonFields> extends UserEventHandler<TData> implements DataismEventHandlerInterface<TData> {
  static instance: DataismUserEvents<CommonFields>

  protected handlers: DataismEventHandlerInterface<TData>[]

  private constructor(handlers?: DataismEventHandlerInterface<TData>[]) {
    super()
    this.handlers = handlers ?? [
      new DataismEventHandler<TData>(),
      new FacebookEventHandler<TData>(),
      new GoogleEventHandler<TData>()]
  }

  static get debugEnabled() {
    return localStorage.getItem('debugEvents') === 'true'
  }

  static get logger() {
    return this.debugEnabled ? console : undefined
  }

  get logger() {
    return DataismUserEvents.logger
  }

  static enableDebugging(enable: boolean) {
    if (enable) {
      localStorage.setItem('debugEvents', 'true')
    } else {
      localStorage.removeItem('debugEvents')
    }
  }

  static get<T extends CommonFields = CommonFields>(mixpanel?: Mixpanel) {
    this.instance = this.instance ?? (() => {
      const handlers = [new DataismEventHandler<T>(), new FacebookEventHandler<T>(), new GoogleEventHandler<T>()]
      if (mixpanel) {
        handlers.push(new MixpanelEventHandler<T>(mixpanel))
        // this.logger?.log('MpEh added')
      } {
        // this.logger?.log('MpEh not added')
      }
      return new DataismUserEvents<T>(handlers)
    })()
    return this.instance
  }

  async accountCreated(fields: AccountCreatedFields) {
    this.logger?.log('ue.accountCreated-start')
    await Promise.allSettled(this.handlers.map(handler => handler.accountCreated(fields)))
    this.logger?.log('ue.accountCreated-finish')
  }

  async accountLogin(fields: AccountLoginFields) {
    this.logger?.log('ue.accountLogin-start')
    await Promise.allSettled(this.handlers.map(handler => handler.accountLogin(fields)))
    this.logger?.log('ue.accountLogin-finish')
  }

  async accountLogout(fields: AccountLogoutFields) {
    this.logger?.log('ue.accountLogout-start')
    await Promise.allSettled(this.handlers.map(handler => handler.accountLogout(fields)))
    this.logger?.log('ue.accountLogout-finish')
  }

  async affiliateClick(fields: AffiliateClickFields) {
    this.logger?.log('ue.affiliateClick-start')
    await Promise.allSettled(this.handlers.map(handler => handler.affiliateClick(fields)))
    this.logger?.log('ue.affiliateClick-finish')
  }

  async error(fields: ErrorFields) {
    this.logger?.log('ue.error-start')
    await Promise.allSettled(this.handlers.map(handler => handler.error(fields)))
    this.logger?.log('ue.error-finish')
  }

  async funnelStarted<T extends FunnelStartedFields>(fields: T) {
    this.logger?.log('ue.funnelStarted-start')
    await Promise.allSettled(this.handlers.map(handler => handler.funnelStarted(fields)))
    this.logger?.log('ue.funnelStarted-finish')
  }

  async journeyCompleted(fields: JourneyCompletedFields) {
    this.logger?.log('ue.journeyCompleted-start')
    await Promise.allSettled(this.handlers.map(handler => handler.journeyCompleted(fields)))
    this.logger?.log('ue.journeyCompleted-finish')
  }

  async journeyProgress(fields: JourneyProgressFields) {
    this.logger?.log('ue.journeyProgress-start')
    await Promise.allSettled(this.handlers.map(handler => handler.journeyProgress(fields)))
    this.logger?.log('ue.journeyProgress-finish')
  }

  async journeyStarted(fields: JourneyStartedFields) {
    this.logger?.log('ue.journeyStarted-start')
    await Promise.allSettled(this.handlers.map(handler => handler.journeyStarted(fields)))
    this.logger?.log('ue.journeyStarted-finish')
  }

  async quizComplete(fields: QuizCompleteFields) {
    this.logger?.log('ue.quizComplete-start')
    await Promise.allSettled(this.handlers.map(handler => handler.quizComplete(fields)))
    this.logger?.log('ue.quizComplete-finish')
  }

  async referralSent<T extends ReferralSentFields>(fields: T) {
    this.logger?.log('ue.referralSent-start')
    await Promise.allSettled(this.handlers.map(handler => handler.referralSent(fields)))
    this.logger?.log('ue.referralSent-finish')
  }

  async rewardGranted(fields: RewardGrantedFields) {
    this.logger?.log('ue.rewardGranted-start')
    await Promise.allSettled(this.handlers.map(handler => handler.rewardGranted(fields)))
    this.logger?.log('ue.rewardGranted-finish')
  }

  async taskCompleted(fields: TaskCompletedFields) {
    this.logger?.log('ue.taskCompleted-start')
    await Promise.allSettled(this.handlers.map(handler => handler.taskCompleted(fields)))
    this.logger?.log('ue.taskCompleted-finish')
  }

  async taskProgress(fields: TaskProgressFields) {
    this.logger?.log('ue.taskProgress-start')
    await Promise.allSettled(this.handlers.map(handler => handler.taskProgress(fields)))
    this.logger?.log('ue.taskProgress-finish')
  }

  async taskStarted(fields: TaskStartedFields) {
    this.logger?.log('ue.taskStarted-start')
    await Promise.allSettled(this.handlers.map(handler => handler.taskStarted(fields)))
    this.logger?.log('ue.taskStarted-finish')
  }

  async testStarted<T extends TestStartedFields>(fields: T) {
    this.logger?.log('ue.testStarted-start')
    await Promise.allSettled(this.handlers.map(handler => handler.testStarted(fields)))
    this.logger?.log('ue.testStarted-finish')
  }

  async userClick<T extends UserClickFields>(fields: T) {
    this.logger?.log('ue.userClick-start')
    await Promise.allSettled(this.handlers.map(handler => handler.userClick(fields)))
    this.logger?.log('ue.userClick-finish')
  }

  async viewContent<T extends ViewContentFields>(fields: T) {
    this.logger?.log('ue.viewContent-start')
    await Promise.allSettled(this.handlers.map(handler => handler.viewContent(fields)))
    this.logger?.log('ue.viewContent-finish')
  }
}

globalThis.debugEvents = DataismUserEvents
