import type {
  CommonFields,
  FunnelStartedFields, PurchaseFields, TestStartedFields, UserClickFields, ViewContentFields,
} from '@xylabs/pixel'
import { UserEventHandler } from '@xylabs/pixel'
import type { Promisable } from '@xylabs/sdk-js'
import { LatestUtmPayload } from '@xyo-network/react-advertising'

import { ExperimentsHandler } from '#lib'

import type {
  AccountCreatedFields, AccountLoginFields, AccountLogoutFields, AffiliateClickFields, DataismEventHandlerInterface, ErrorFields, JourneyCompletedFields,
  JourneyProgressFields,
  JourneyStartedFields,
  QuizCompleteFields,
  ReferralSentFields,
  RewardGrantedFields,
  TaskCompletedFields,
  TaskProgressFields,
  TaskStartedFields,
} from './DataismEventHandlerInterface.ts'
import { XyEvents } from './Xy/index.ts'

export interface Event<TData extends CommonFields = CommonFields> {
  send(fields: TData, eventId?: string): Promisable<void>
}

export interface Events<T extends CommonFields = CommonFields> {
  custom(name: string): Event<T>
  purchase(): Event<PurchaseFields>
  userClick(): Event<UserClickFields>
  viewContent(): Event<ViewContentFields>
}

export class DataismEventHandler<T extends CommonFields = CommonFields> extends UserEventHandler<T> implements DataismEventHandlerInterface<T> {
  private _events = new XyEvents()

  get events(): Events {
    return this._events
  }

  async accountCreated(fields: AccountCreatedFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('accountCreated').send(fields)
  }

  async accountLogin(fields: AccountLoginFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('accountLogin').send(fields)
  }

  async accountLogout(fields: AccountLogoutFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('accountLogout').send(fields)
  }

  async affiliateClick(fields: AffiliateClickFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('affiliateClick').send(fields)
  }

  async error(fields: ErrorFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('error').send(fields)
  }

  async funnelStarted(fields: FunnelStartedFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('funnelStarted').send(fields)
  }

  async journeyCompleted(fields: JourneyCompletedFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('journeyCompleted').send(fields)
  }

  async journeyProgress(fields: JourneyProgressFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('journeyProgress').send(fields)
  }

  async journeyStarted(fields: JourneyStartedFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('journeyStarted').send(fields)
  }

  async quizComplete(fields: QuizCompleteFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('quizComplete').send(fields)
  }

  async referralSent(fields: ReferralSentFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('referralSent').send(fields)
  }

  async rewardGranted(fields: RewardGrantedFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('rewardGranted').send(fields)
  }

  async taskCompleted(fields: TaskCompletedFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('taskCompleted').send(fields)
  }

  async taskProgress(fields: TaskProgressFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('taskProgress').send(fields)
  }

  async taskStarted(fields: TaskStartedFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('taskStarted').send(fields)
  }

  async testStarted(fields: TestStartedFields) {
    fields = await this.getDefaultFieldsData(fields)
    await this.events.custom('testStarted').send(fields)
  }

  async userClick(fields: UserClickFields) {
    fields = await this.getDefaultFieldsData<UserClickFields>(fields)
    await this.events.userClick().send(fields)
  }

  async viewContent(fields: ViewContentFields) {
    fields = await this.getDefaultFieldsData<ViewContentFields>(fields)
    await this.events.viewContent().send(fields)
  }

  private async getDefaultFieldsData<TFields extends CommonFields>(fields: TFields): Promise<TFields> {
    // add user data to fields
    const userData = ExperimentsHandler.userData
    fields.testData = userData

    // add UTM properties to fields
    const payload = await LatestUtmPayload()
    if (payload) {
      Object.entries(payload).map(([key, value]) => {
        if (key.includes('utm')) {
          // prefix the key with 'xyo_' since providers like mixpanel camp on utm_ keys
          fields[`xyo_${key}` as keyof TFields] = value
        }
      })
    }

    // return the updated fields
    return fields
  }
}
