import { GenericHeroJourneyImageSlug } from '#img'
import type { Journey } from '#models'

import { GenericJourneySlug } from './Slug.ts'
import { GenericTaskData } from './WithTask.ts'

export const GenericJourneyData: Journey = {
  description: 'Generic Journey Description',
  timeToComplete: (60 * 5) * 1000,
  heroImage: GenericHeroJourneyImageSlug,
  schema: 'org.dataism.journey',
  slug: GenericJourneySlug,
  tasks: [GenericTaskData],
  taskTypes: ['quiz'],
  title: 'Generic Journey Title',
  topics: ['topic-1', 'topic-2'],
}
