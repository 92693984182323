import type { BadgeTaskReward } from '#models'
import { BadgeTaskRewardSchema } from '#models'

export const LogoMemoryBadge: BadgeTaskReward = {
  schema: BadgeTaskRewardSchema,
  badgeId: 'badge-id',
  slug: 'slug-badge-logo-memory-level-1',
  rewardType: 'badge',
  title: 'Logo Memory: Level 1',
}
