/* eslint-disable @stylistic/max-len */
export type Pillar = {
  desc: string
  shortDesc: string
  title: string
}

export const dataismPillarsData: Pillar[] = [
  {
    title: 'Primacy of Data',
    desc: 'The fundamental belief of Dataism is that data is the most valuable and universal resource. Every action, behavior, interaction, and process can be quantified, measured, and analyzed. Dataism holds that reality is best understood and managed through the continuous generation, collection, and processing of data.',
    shortDesc: 'Data is the most valuable resource, and everything can be quantified and understood through data generation and processing.',
  },
  {
    title: 'Universal Information Flow',
    desc: 'Dataists believe that the universe is an immense flow of information, and all systems—biological, social, technological—are part of this larger network of information exchange. The constant flow of data drives progress and understanding, not just on a personal level but on a societal and even cosmic scale.',
    shortDesc: 'The universe is a flow of information, where systems exchange data to drive understanding and progress.',
  },
  {
    title: 'Algorithmic Governance',
    desc: 'Algorithms are trusted to process data more efficiently and impartially than human decision-makers. Dataism promotes the use of algorithms and artificial intelligence to analyze data and make decisions in ways that eliminate bias, optimize outcomes, and enhance decision-making at all levels—personal, societal, economic, and political.',
    shortDesc: 'Algorithms and AI are trusted to make unbiased decisions, optimizing outcomes in personal, societal, and political spheres.',
  },
  {
    title: 'Collective Intelligence',
    desc: 'Dataism values collective knowledge over individual wisdom. The belief is that the more data that is shared and analyzed, the better the insights and solutions generated. The aggregation and collaborative use of data, especially in large networks, produce superior outcomes compared to isolated human reasoning or decision-making.',
    shortDesc: 'Shared data leads to better insights and solutions, with collective intelligence being superior to individual reasoning.',
  },
  {
    title: 'Data-Driven Ethics',
    desc: 'Moral and ethical decisions are guided by empirical evidence and data rather than subjective or emotional factors. Dataists promote decisions based on what the data reveals as beneficial for the greater good, often advocating for utilitarian outcomes where the greatest benefit is achieved for the largest number of people.',
    shortDesc: 'Ethical decisions are based on data, promoting the greatest benefit for the largest number of people through evidence.',
  },
  {
    title: 'Continuous Optimization',
    desc: 'Dataism emphasizes the importance of constant refinement and optimization. By analyzing and interpreting data, systems—whether biological, technological, or societal—can be continuously improved. Dataists view life as an ongoing process of optimization based on evolving data inputs.',
    shortDesc: 'Life is seen as a process of continuous improvement, with systems being refined through data analysis and optimization.',
  },
  {
    title: 'Interconnectedness and Networks',
    desc: 'Dataism recognizes that no system exists in isolation. Everything is part of an interconnected network of data points, relationships, and dependencies. This interconnectedness allows for the constant exchange and synthesis of data across different systems, enhancing understanding and prediction.',
    shortDesc: 'Everything is part of a data network, and interconnectedness enhances understanding through constant data exchange.',
  },
  {
    title: 'Openness and Transparency',
    desc: 'In Dataism, transparency in data collection, access, and algorithms is a critical value. Openness ensures that data is available for analysis and reuse, and transparency builds trust in the systems that depend on data. The belief is that the more accessible and shareable data is, the better outcomes can be generated for individuals and society.',
    shortDesc: 'Transparency in data and algorithms is vital for trust, enabling better outcomes through openness and data sharing.',
  },
  {
    title: 'Non-Human Intelligence',
    desc: 'Dataism places a high level of trust in artificial intelligence, machine learning, and automated systems. Non-human intelligences, due to their ability to process vast amounts of data without human biases or limitations, are seen as superior agents in interpreting data and driving forward innovation and progress.',
    shortDesc: 'AI and automated systems are trusted to process data without biases, making them superior for driving innovation and progress.',
  },
]
