import { onMessage } from '@firebase/messaging'
import {
  Box, Container, Stack, Typography,
} from '@mui/material'
import { ButtonEx } from '@xylabs/react-button'
import { FlexCol } from '@xylabs/react-flexbox'
import React, { useState } from 'react'

import { DataismBasePage } from '#chrome'
import { FullPageResponsiveBox } from '#components'
import { useUser } from '#contexts'
import {
  getBrowserNotificationToken, messaging, registerBrowserToken, requestNotificationPermission,
} from '#lib'

/**
 * Route for testing Iterable Web Push Notifications.
 * https://support.iterable.com/hc/en-us/articles/115004760086-Web-Push-Overview
 */
export const MessagingPage: React.FC = () => {
  const [token, setToken] = useState('')
  const [message, setMessage] = useState('')
  const user = useUser()

  // TODO: Handle this globally somewhere with a toast or similar
  onMessage(messaging, (payload) => {
    console.log('Message received.', payload)
    setMessage(JSON.stringify(payload))
  })

  return (
    <DataismBasePage maxWidth="xl" title="Messaging">
      <FullPageResponsiveBox id="messaging">
        <FlexCol
          sx={{
            backgroundPosition: 'center bottom', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', alignItems: 'stretch',
          }}
        >
          <Container sx={{ display: 'flex', justifyContent: 'center' }}>
            <Stack flexDirection="column" alignItems="flex-start" gap={2}>
              <Box
                component="form"
                sx={{
                  display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, maxWidth: 400, margin: '0 auto',
                }}
              >
                <ButtonEx onClick={async () => {
                  try {
                    // Get the user's permission to receive notifications
                    await requestNotificationPermission()
                    // If we have a user and know their email
                    const email = user?.user?.email
                    if (email) {
                      // Get the token for the user's browser to send them notifications
                      const browserToken = await getBrowserNotificationToken()
                      setToken(browserToken)
                      // Register the token to the user's email to allow sending them push notifications
                      await registerBrowserToken(browserToken, email)
                    }
                  } catch (error) {
                    console.error('Error getting token:', error)
                  }
                }}
                >
                  Register Browser Token
                </ButtonEx>
                <Typography variant="body1">{`Token: ${token}`}</Typography>
                <Typography variant="body1">{message ? `Message: ${message}` : ''}</Typography>
              </Box>
            </Stack>
          </Container>
        </FlexCol>
      </FullPageResponsiveBox>
    </DataismBasePage>
  )
}
