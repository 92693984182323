import { Container } from '@mui/material'
import type { BusyBoxProps } from '@xylabs/react-flexbox'
import { FlexCol } from '@xylabs/react-flexbox'
import React from 'react'

export const MissionHero: React.FC<BusyBoxProps> = ({ ...props }) => {
  return (
    <FlexCol
      alignItems="stretch"
      sx={{
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
      {...props}
    >
      <Container sx={{ display: 'flex', justifyContent: 'center' }}>
        <h1>Mission</h1>
      </Container>
    </FlexCol>
  )
}
