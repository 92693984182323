import type { BadgeTaskReward } from '#models'
import { BadgeTaskRewardSchema } from '#models'

export const DataistPersonaQuizBadge: BadgeTaskReward = {
  schema: BadgeTaskRewardSchema,
  badgeId: 'dataist-persona-quiz-badge-id',
  slug: 'slug-dataist-persona-quiz-badge-id',
  rewardType: 'badge',
  title: 'Dataist Persona Badge',
}
