import type { ToolbarProps, TooltipProps } from '@mui/material'
import {
  Toolbar, useMediaQuery, useTheme,
} from '@mui/material'
import { FlexGrowRow } from '@xylabs/react-flexbox'
import React, { useState } from 'react'

import type { DropdownSectionDataProps } from '#components'

import { DropdownSection } from './Dropdown/index.ts'
import { NavbarData } from './NavData/index.ts'

export interface ContextToolbarProps extends ToolbarProps {
  navBarData: DropdownSectionDataProps[]
}

export const ContextToolbar: React.FC<ContextToolbarProps> = ({ navBarData, ...props }) => {
  const theme = useTheme()
  const isNavbarMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const [navigationController, setNavigationController] = useState<[number, boolean]>([0, false])

  return (
    <Toolbar {...props}>
      <FlexGrowRow>
        {!isNavbarMobile && navBarData.map((section, index) => (
          <DropdownSection
            dropdownId={index}
            data={section}
            key={section.name}
            open={navigationController[0] === index ? navigationController[1] : false}
            openController={setNavigationController}
            placement={
              index === 0
                ? ('bottom-start' as TooltipProps['placement'])
                : index === NavbarData.length - 1
                  ? ('bottom-end' as TooltipProps['placement'])
                  : ('bottom' as TooltipProps['placement'])
            }
          />
        ))}
      </FlexGrowRow>
    </Toolbar>
  )
}
