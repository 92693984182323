import type { BadgeTaskReward } from '#models'
import { BadgeTaskRewardSchema } from '#models'

export const TwoFactorAuthenticationBasicsBadge: BadgeTaskReward = {
  schema: BadgeTaskRewardSchema,
  badgeId: 'badge-id',
  slug: 'slug-badge-two-factor-authentication-basics',
  rewardType: 'badge',
  title: '2-Factor Auth Basics Badge',
}
