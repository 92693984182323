/**
 * Requests permission to send notifications. This method is somewhat
 * idempotent in that it will not prompt the user if they have
 * already agreed to receive notifications.
 */
export const requestNotificationPermission = async () => {
  console.log('Requesting permission...')
  const permission = await Notification.requestPermission()
  if (permission === 'granted') {
    console.log('Notification permission granted.')
  }
}
