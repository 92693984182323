import React from 'react'

import { DataismBasePage } from '#chrome'

import { ResourcesHero } from './Hero.tsx'

export const ResourcesPage: React.FC = () => {
  return (
    <DataismBasePage title="Resources">
      <ResourcesHero />
    </DataismBasePage>
  )
}
