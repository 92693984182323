import {
  Box, Slide, useMediaQuery, useScrollTrigger, useTheme,
} from '@mui/material'
import type { AppBarExProps } from '@xylabs/react-appbar'
import type { ReactNode } from 'react'
import React, { useEffect, useState } from 'react'
import type { To } from 'react-router-dom'

import { MobileNavDialog } from '#components'

import { AppBarButtonsToolbar } from './AppBarButtons/index.ts'
import { ContextToolbar } from './ContextToolbar.tsx'
import { DataismAppBarEx } from './DataismAppBarEx.tsx'
import { Logo } from './Logo/index.ts'
import { NavbarData } from './NavData/index.ts'

export interface DataismAppBarExProps extends AppBarExProps {
  ctaLink?: To | string
  ctaText?: string
  customButton?: ReactNode
}

export const DataismAppBar: React.FC<DataismAppBarExProps> = ({
  ctaLink, ctaText, customButton, sx, contextToolbar, systemToolbar, ...props
}) => {
  const theme = useTheme()
  const isNavbarMobile = useMediaQuery(theme.breakpoints.down('lg'))
  const trigger = useScrollTrigger({ target: globalThis.window, threshold: 500 })
  const [, setIsAtTop] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop
      if (scrollTop < 550) {
        // eslint-disable-next-line @eslint-react/hooks-extra/no-direct-set-state-in-use-effect
        setIsAtTop(true)
      } else {
        // eslint-disable-next-line @eslint-react/hooks-extra/no-direct-set-state-in-use-effect
        setIsAtTop(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    handleScroll()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Slide direction="down" in={!trigger}>
      <Box width="100%">
        <DataismAppBarEx
          position="sticky"
          elevation={0}
          container="xl"
          systemToolbar={contextToolbar}
          contextToolbar={systemToolbar}
          sx={{
            backgroundColor: theme.palette.background.default,
            color: theme.palette.getContrastText(theme.palette.background.default),
            width: '100%',
            ...sx,
          }}
          onClick={event => event.stopPropagation()}
          {...props}
        >
          <Logo sx={{ justifyContent: 'flex-start' }} />
          <ContextToolbar sx={{ justifyContent: 'center' }} navBarData={NavbarData} />
          <AppBarButtonsToolbar
            sx={{ justifyContent: 'flex-end', flexGrow: 1 }}
            ctaLink={ctaLink}
            ctaText={ctaText}
            customButton={customButton}
            disableGutters
          >
            {isNavbarMobile
              ? <MobileNavDialog navBarData={NavbarData} />
              : null}
          </AppBarButtonsToolbar>
        </DataismAppBarEx>
      </Box>
    </Slide>
  )
}
