import type { ActionCodeSettings, Auth } from '@firebase/auth'
import {
  isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink,
} from '@firebase/auth'
import { assertEx } from '@xylabs/sdk-js'

export const sendSignInEmail = async (email: string, auth: Auth) => {
  const actionCodeSettings: ActionCodeSettings = {
    url: `${import.meta.env.VITE_HOST_URL}/account/email/verify`,
    handleCodeInApp: true,
  }

  try {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings)
    // Save the email locally for later retrieval after the user clicks the link
    globalThis.localStorage.setItem('emailForSignIn', email)
    console.log('Email link sent to:', email, 'with url', actionCodeSettings.url)
  } catch (error) {
    console.error('Error sending email link:', error)
  }
}

export const completeSignIn = async (link: string, auth: Auth, emailArg?: string) => {
  // Check if this is a sign-in link
  if (isSignInWithEmailLink(auth, link)) {
    // Retrieve the email address from local storage
    let email = emailArg ?? assertEx(globalThis.localStorage.getItem('emailForSignIn'), 'Email not found, please provide it manually')
    try {
      const result = await signInWithEmailLink(auth, email, globalThis.location.href)
      console.log('Successfully signed in!', result.user)
      // Clear the email from storage
      globalThis.localStorage.removeItem('emailForSignIn')
    } catch (error) {
      console.error('Error completing sign-in:', error)
    }
  }
}
