import { usePromise } from '@xylabs/react-promise'

import { useUser } from '#contexts'

import { getNextJourney } from '../../helpers/index.ts'

export const useGetNextJourney = () => {
  const user = useUser()

  return usePromise(async () => {
    if (user?.user?.uid) {
      return await getNextJourney(user)
    }
  }, [user])
}
