import { DataistPersonaJourneyImageSlug } from '#img'
import {
  type Journey, JourneySchema, type Slug,
} from '#models'

import { DataistPersonaQuizTaskData } from './WithTask.ts'

export const DataistPersonaJourneySlug: Slug = 'slug-dataist-persona-journey'

export const DataistPersonaJourneyData: Journey = {
  description: 'Discover what type of Dataist you are by taking this quiz.',
  timeToComplete: (60 * 2) * 1000,
  heroImage: DataistPersonaJourneyImageSlug,
  schema: JourneySchema,
  slug: DataistPersonaJourneySlug,
  tasks: [DataistPersonaQuizTaskData],
  taskTypes: ['quiz'],
  title: 'What Type of Dataist Are You?',
  topics: ['dataism'],
}
