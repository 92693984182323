import type { UserContextType } from '#contexts'
import type { JourneyStatus } from '#models'
import { FirestoreRepository } from '#repository'

// TODO - Can this be a number?
export const journeyStatusIdPredicate = (data: JourneyStatus) => `${data.timestamp}`

export const JourneyStatusesCollection = 'journeyStatuses'

export const getJourneyStatusRepository = (user: UserContextType) => {
  const userCollection = `users/${user?.user?.uid}/${JourneyStatusesCollection}`
  return new FirestoreRepository<JourneyStatus>(userCollection, journeyStatusIdPredicate)
}
