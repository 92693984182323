import { CloseRounded, MenuRounded } from '@mui/icons-material'
import type { DialogProps } from '@mui/material'
import {
  AppBar,
  Dialog,
  IconButton,
  Stack, Toolbar,
  Typography,
  useTheme,
} from '@mui/material'
import { LinkEx } from '@xylabs/react-link'
import React from 'react'

import { useAuth } from '#contexts'
import { logout } from '#users'

import type { DropdownSectionDataProps } from '../props/index.ts'
import { MobileNavSectionDialog } from './MobileNavSectionDialog.tsx'
import { SlideTransition } from './Transition.tsx'

export interface MobileNavDialogProps extends Omit<DialogProps, 'open'> {
  navBarData: DropdownSectionDataProps[]
  showLogout?: boolean
}

export const MobileNavDialog: React.FC<MobileNavDialogProps> = ({
  navBarData, showLogout, ...props
}) => {
  const theme = useTheme()
  const auth = useAuth()

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = () => {
    logout(auth).then(() => {
      handleClose()
    }).catch((error) => {
      console.error(error)
    })
  }

  return (
    <>
      <IconButton aria-label="menu-button" onClick={handleClickOpen}>
        <MenuRounded fontSize="large" />
      </IconButton>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={SlideTransition}
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: theme.palette.background.paper, backgroundImage: 'none', paddingLeft: 2, paddingRight: 2,
          },
        }}
        {...props}
      >
        <AppBar
          sx={{
            position: 'relative', paddingY: 1, marginBottom: 2,
          }}
          color="transparent"
          elevation={0}
        >
          <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
            <IconButton aria-label="menu-button" onClick={handleClose}>
              <CloseRounded fontSize="large" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Stack gap={3} flexDirection="column" alignItems="flex-start">
          {navBarData.map((item) => {
            return (
              <MobileNavSectionDialog key={item.name} closeAllDialogs={handleClose} {...item} />
            )
          })}
          { showLogout
            ? (
                <LinkEx component="button" color="inherit" onClick={handleLogout}>
                  <Typography fontWeight={theme.typography.fontWeightMedium} variant="h3">Logout</Typography>
                </LinkEx>
              )
            : null}
        </Stack>
      </Dialog>
    </>
  )
}
