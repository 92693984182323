import type { Payload } from '@xyo-network/payload-model'
import { isPayloadOfSchemaTypeWithMeta } from '@xyo-network/payload-model'

import type { TaskRewardBase } from './Base.ts'

export const BadgeTaskRewardSchema = 'org.dataism.journey.task.reward.badge' as const
export type BadgeTaskRewardSchema = typeof BadgeTaskRewardSchema

export interface BadgeTaskRewardFields extends TaskRewardBase {
  badgeId: string
  image?: string
  rewardType: 'badge'
}

export type BadgeTaskReward = Payload<BadgeTaskRewardFields, BadgeTaskRewardSchema>
export const isBadgeTaskReward = isPayloadOfSchemaTypeWithMeta<BadgeTaskReward>(BadgeTaskRewardSchema)
