import {
  Alert, AlertTitle, Divider, Stack,
} from '@mui/material'
import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { DataismBasePage } from '#chrome'

import { Breakdown } from './Breakdown.tsx'
import { PillarsHero } from './Hero.tsx'

export const PillarsPage: React.FC = () => {
  const [params] = useSearchParams()
  const fromQuiz = params.get('from_quiz')

  return (
    <DataismBasePage title="Pillars of Dataism">
      <PillarsHero />
      <Divider flexItem />
      <Breakdown />
      <Divider flexItem />

      {fromQuiz && (
        <Stack sx={{ my: 4 }} alignItems="center">
          <Alert severity="success">
            <AlertTitle>Ready to take the Quiz?</AlertTitle>
            Close this window and return to the journey to keep going.
          </Alert>
        </Stack>
      )}
    </DataismBasePage>
  )
}
