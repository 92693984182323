import type { EmptyObject } from '@xylabs/sdk-js'

import type { IRepository } from '../../interface/index.ts'
import type { MemoryIdPredicate } from './IdPredicate.ts'
import { MemoryQueryBuilder } from './QueryBuilder.ts'

export class MemoryRepository<TData extends EmptyObject = EmptyObject> implements IRepository<TData, MemoryQueryBuilder<TData>> {
  private readonly idPredicate: MemoryIdPredicate<TData>
  private store: Map<string, TData> = new Map()

  constructor(idPredicate: MemoryIdPredicate<TData>, initialData: TData[] = []) {
    this.idPredicate = idPredicate
    for (const data of initialData) {
      const id = this.idPredicate(data)
      this.store.set(id, data)
    }
  }

  add(entity: TData): Promise<void> {
    const id = this.idPredicate(entity)
    this.store.set(id, entity)
    return Promise.resolve()
  }

  count(): Promise<number> {
    return Promise.resolve(this.store.size)
  }

  createQueryBuilder(): MemoryQueryBuilder<TData> {
    return new MemoryQueryBuilder()
  }

  delete(entity: TData): Promise<void> {
    const id = this.idPredicate(entity)
    this.store.delete(id)
    return Promise.resolve()
  }

  deleteById(id: string): Promise<void> {
    this.store.delete(id)
    return Promise.resolve()
  }

  find(queryBuilder: MemoryQueryBuilder<TData>): Promise<TData[]> {
    const filter = queryBuilder.build()
    const results: TData[] = filter([...this.store.values()])
    return Promise.resolve(results)
  }

  getAll(): Promise<TData[]> {
    return Promise.resolve([...this.store.values()])
  }

  getById(id: string): Promise<TData | null> {
    return Promise.resolve(this.store.get(id) || null)
  }

  update(entity: TData): Promise<void> {
    const id = this.idPredicate(entity)
    this.store.set(id, entity)
    return Promise.resolve()
  }
}
