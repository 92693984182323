import React from 'react'

import { DataismBasePage } from '#chrome'

import { DataistPersonas } from './DataistPersonas.tsx'
import { HomeHero } from './Hero.tsx'
import { Pillars } from './Pillars.tsx'
import { WhatIsDataism } from './WhatIsDataism.tsx'

export const HomePage: React.FC = () => {
  return (
    <DataismBasePage title="The Human Side of Data">
      <HomeHero />
      <WhatIsDataism />
      <Pillars />
      <DataistPersonas />
    </DataismBasePage>
  )
}
