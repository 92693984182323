import type { AppBarProps, ToolbarProps } from '@mui/material'
import {
  AppBar, Container, Toolbar, useMediaQuery, useTheme,
} from '@mui/material'
import { FlexGrowRow, FlexRow } from '@xylabs/react-flexbox'
import type { ReactElement } from 'react'
import React from 'react'

export interface AppBarExProps extends AppBarProps {
  container?: 'xl' | 'lg' | 'md' | 'sm' | 'xs'
  contextToolbar?: ReactElement<ToolbarProps>
  menu?: ReactElement<ToolbarProps>
  responsive?: boolean
  systemToolbar?: ReactElement<ToolbarProps>
}

const AppBarExInner: React.FC<AppBarExProps> = ({
  children, menu, contextToolbar, systemToolbar, responsive, ...props
}) => {
  const { breakpoints } = useTheme()
  const belowSm = useMediaQuery(breakpoints.down('sm'))
  return (
    <>
      <FlexRow flexWrap="nowrap" justifyContent="flex-start" {...props}>
        {contextToolbar ?? null}
        <FlexGrowRow>{belowSm && responsive ? null : children}</FlexGrowRow>
        {systemToolbar ?? null}
        {menu}
      </FlexRow>
      {belowSm && children && responsive
        ? <Toolbar>{children}</Toolbar>
        : null}
    </>
  )
}

export const DataismAppBarEx: React.FC<AppBarExProps> = ({
  container, children, menu, contextToolbar, systemToolbar, responsive, ...props
}) => {
  return (
    <AppBar position="static" {...props}>
      {container
        ? (
            <Container maxWidth={container}>
              <AppBarExInner {...{
                children, contextToolbar, menu, responsive, systemToolbar,
              }}
              />
            </Container>
          )
        : (
            <AppBarExInner {...{
              children, contextToolbar, menu, responsive, systemToolbar,
            }}
            />
          )}
    </AppBar>
  )
}
