import type { QuizTaskValidation, Slug } from '#models'

export const GeneralInternetQuizValidationSlug: Slug = 'slug-general-internet-quiz-validation'

export const GeneralInternetQuizValidation: QuizTaskValidation = {
  outcomes: [
    { thresholds: { min: { google: 1 } } },
  ],
  type: 'quiz',
  schema: 'org.dataism.journey.task.validation.quiz',
  slug: GeneralInternetQuizValidationSlug,
}
