import { useCaptureUtmLocation } from '@xyo-network/react-advertising'
import { ErrorRender } from '@xyo-network/react-error'
import type { PropsWithChildren } from 'react'
import React from 'react'

export const CaptureUtmParams: React.FC<PropsWithChildren> = ({ children }) => {
  const { error } = useCaptureUtmLocation()

  return (
    <>
      <ErrorRender error={error} scope="CaptureUtmParams" />
      {children}
    </>
  )
}
