import { default as PrivacyPolicy1 } from './Privacy_Policy_050924_Page_01.png'
import { default as PrivacyPolicy2 } from './Privacy_Policy_050924_Page_02.png'
import { default as PrivacyPolicy3 } from './Privacy_Policy_050924_Page_03.png'
import { default as PrivacyPolicy4 } from './Privacy_Policy_050924_Page_04.png'
import { default as PrivacyPolicy5 } from './Privacy_Policy_050924_Page_05.png'
import { default as PrivacyPolicy6 } from './Privacy_Policy_050924_Page_06.png'
import { default as PrivacyPolicy7 } from './Privacy_Policy_050924_Page_07.png'
import { default as PrivacyPolicy8 } from './Privacy_Policy_050924_Page_08.png'
import { default as PrivacyPolicy9 } from './Privacy_Policy_050924_Page_09.png'
import { default as PrivacyPolicy10 } from './Privacy_Policy_050924_Page_10.png'
import { default as PrivacyPolicy11 } from './Privacy_Policy_050924_Page_11.png'
import { default as PrivacyPolicy12 } from './Privacy_Policy_050924_Page_12.png'
import { default as PrivacyPolicy13 } from './Privacy_Policy_050924_Page_13.png'
import { default as PrivacyPolicy14 } from './Privacy_Policy_050924_Page_14.png'
import { default as PrivacyPolicy15 } from './Privacy_Policy_050924_Page_15.png'

export const PrivacyPolicyPages = [
  PrivacyPolicy1,
  PrivacyPolicy2,
  PrivacyPolicy3,
  PrivacyPolicy4,
  PrivacyPolicy5,
  PrivacyPolicy6,
  PrivacyPolicy7,
  PrivacyPolicy8,
  PrivacyPolicy9,
  PrivacyPolicy10,
  PrivacyPolicy11,
  PrivacyPolicy12,
  PrivacyPolicy13,
  PrivacyPolicy14,
  PrivacyPolicy15,
]
