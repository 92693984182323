import type { CardProps } from '@mui/material'
import {
  Alert,
  AlertTitle,
  Button,
  Card, CardContent, CardHeader, Grow, TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { ButtonEx } from '@xylabs/react-button'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import { useColorSchemeEx } from '@xylabs/react-invertible-theme'
import { ErrorRender } from '@xyo-network/react-error'
import type {
  ChangeEventHandler,
  FormEventHandler,
} from 'react'
import React, {
  forwardRef, useMemo, useState,
} from 'react'
import type { To } from 'react-router-dom'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useAuth } from '#contexts'
import { EmailValidatorConstants } from '#lib'
import {
  loginWithFacebook, loginWithGithub, loginWithGoogle, loginWithTwitter, sendSignInEmail,
} from '#users'

import {
  GithubIconBlack, GithubIconWhite, GoogleIcon, MetaIcon, XIconBlack,
  XIconWhite,
} from './icons/index.ts'

export type LoginState = 'create' | 'login'

/**
 * A regex for testing if a string is an email
 * NOTE: Intentionally to not allow `+` to prevent email aliases
 */
const emailRegex = /^(?![%+._-])(?!.*\.\.)[\w%.-]+@[\d.A-Za-z-]+\.[A-Za-z]{2,}$/

/**
 * Tests if a string is a valid email
 * @param email The string to test
 * @returns True if the string is a valid email, false otherwise
 */
const isValidEmail = (email: string) => {
  return emailRegex.test(email)
}

export interface LoginCardProps extends CardProps {
  email?: string | null
  hideLoginStateToggle?: boolean
  hidePasswordField?: boolean
  loginState?: LoginState
  redirectPath?: To
}

export const LoginCard = forwardRef<HTMLDivElement, LoginCardProps>(({
  email: emailProp, hideLoginStateToggle = true, hidePasswordField = true, loginState = 'login', redirectPath, ...props
}, ref) => {
  const [email, setEmail] = useState<string>(emailProp ?? '')
  const [password, setPassword] = useState<string>('')
  const [loginMode, setLoginMode] = useState<LoginState>(loginState)
  const [error, setError] = useState<Error>()
  const [showCheckEmailAlert, setShowCheckEmailAlert] = useState(false)

  const [params] = useSearchParams()
  const createAccount = params.get('create_account')
  useMemo(() => {
    if (createAccount) setLoginMode('create')
  }, [createAccount])

  useMemo(() => {
    const externalEmail = localStorage.getItem(EmailValidatorConstants.LocalStorageKey)
    if (emailProp) {
      setEmail(emailProp)
    } else if (externalEmail) {
      setEmail(externalEmail)
      localStorage.removeItem(EmailValidatorConstants.LocalStorageKey)
    }
  }, [])

  const disabled = useMemo(() => {
    if (!isValidEmail(email)) return true
    if (!hidePasswordField && !password) return true
    return false
  }, [email])

  const theme = useTheme()
  const { darkMode } = useColorSchemeEx()
  const navigate = useNavigate()
  const auth = useAuth()

  // Clear error when email or password changes
  useMemo(() => {
    if (error && (email || password)) {
      setError(undefined)
    }
  }, [email, password])

  const handleEmailChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setShowCheckEmailAlert(false)
    setEmail(e.target.value.trim().toLocaleLowerCase())
  }

  const submitForm: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    const submitter = (e.nativeEvent as SubmitEvent).submitter
    const method = submitter?.dataset.method

    if (method === 'create') {
      sendSignInEmail(email, auth).then(() => {
        if (redirectPath) navigate(redirectPath)
        setShowCheckEmailAlert(true)
      }).catch((e) => {
        setError(e as Error)
      })
    } else {
    // TODO - Check for existing account first before sending email
      sendSignInEmail(email, auth).then(() => {
        if (redirectPath) navigate(redirectPath)
        setShowCheckEmailAlert(true)
      }).catch((e) => {
        setError(e as Error)
      })
    }
  }

  return (
    <FlexGrowCol alignItems="stretch">
      <Card ref={ref} component="form" onSubmit={submitForm} sx={{ display: 'flex' }} {...props}>
        <CardHeader
          title={loginMode === 'login' ? 'Log in' : 'Create an account'}
          subheader={loginMode === 'login' ? 'Welcome back!' : 'Connect an account, or use your email.'}
        />
        <CardContent sx={{
          display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: 2,
        }}
        >
          <FlexGrowCol gap={2} alignItems="stretch">
            <Grow in={!!error} unmountOnExit><div><ErrorRender error={error} scope="LoginForm" /></div></Grow>
            <Grow in={showCheckEmailAlert} unmountOnExit>
              <div>
                <Alert severity="info">
                  <AlertTitle>Done!</AlertTitle>
                  Check your email for a sign-in link.
                </Alert>
              </div>
            </Grow>
            <FlexGrowCol alignItems="stretch" gap={1}>
              <TextField
                label="Email"
                type="email"
                value={email}
                onChange={handleEmailChange}
                required
                fullWidth
              />
              {hidePasswordField
                ? null
                : <TextField label="Password" type="password" value={password} onChange={e => setPassword(e.target.value)} required fullWidth />}
              <FlexGrowCol gap={1} alignItems="stretch">
                {loginMode === 'create'
                  ? (
                      <Button
                        disabled={disabled}
                        variant="contained"
                        fullWidth
                        type="submit"
                        data-method="create"
                        sx={{ fontSize: 18 }}
                      >
                        Create Account
                      </Button>
                    )
                  : (
                      <Button
                        disabled={disabled}
                        variant="contained"
                        fullWidth
                        sx={{ fontSize: 18 }}
                        data-method="continue"
                        type="submit"
                      >
                        Continue With Email
                      </Button>
                    ) }
              </FlexGrowCol>
              <Typography
                variant="body2"
                textAlign="center"
              >
                OR
              </Typography>
              <FlexGrowCol alignItems="stretch" gap={1}>
                <ButtonEx
                  startIcon={(<img src={GoogleIcon} height={24} width={24} />)}
                  variant="outlined"
                  fullWidth
                  onClick={() => loginWithGoogle(auth)}
                >
                  Sign in with Google
                </ButtonEx>
                <ButtonEx
                  startIcon={(<img src={MetaIcon} height={24} width={24} />)}
                  variant="outlined"
                  fullWidth
                  onClick={() => loginWithFacebook(auth)}
                >
                  Sign in with Meta
                </ButtonEx>
                <ButtonEx
                  startIcon={(<img src={darkMode ? XIconWhite : XIconBlack} height={24} width={24} />)}
                  variant="outlined"
                  fullWidth
                  onClick={() => loginWithTwitter(auth)}
                >
                  Sign in with X
                </ButtonEx>
                <ButtonEx
                  startIcon={(<img src={darkMode ? GithubIconWhite : GithubIconBlack} height={24} width={24} />)}
                  variant="outlined"
                  fullWidth
                  onClick={() => loginWithGithub(auth)}
                >
                  Sign in with Github
                </ButtonEx>
              </FlexGrowCol>
              {hideLoginStateToggle
                ? null
                : (
                    <ButtonEx
                      variant="text"
                      disableRipple
                      onClick={() => setLoginMode(loginMode === 'login' ? 'create' : 'login')}
                    >
                      <Typography
                        style={{
                          textDecoration: `underline 1.5px ${theme.palette.text.disabled}`,
                          textUnderlineOffset: '5px',
                          textAlign: 'center',
                        }}
                        variant="body2"
                      >
                        {loginMode === 'login' ? "Don't have an account?" : 'Have an account? Log in.'}
                      </Typography>
                    </ButtonEx>
                  )}
            </FlexGrowCol>
          </FlexGrowCol>
        </CardContent>
      </Card>
    </FlexGrowCol>
  )
})

LoginCard.displayName = 'LoginCard'
