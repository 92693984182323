import type { QuizTaskValidation, Slug } from '#models'

import { TwoFactorAuthenticationBasicsQuiz } from './Data.ts'

export const TwoFactorAuthenticationBasicsValidationSlug: Slug = 'slug-two-factor-authentication-basics-quiz-validation'

export const TwoFactorAuthenticationBasicsValidation: QuizTaskValidation = {
  outcomes: [{ thresholds: { min: { correct: TwoFactorAuthenticationBasicsQuiz.questions.length } } }],
  type: 'quiz',
  schema: 'org.dataism.journey.task.validation.quiz',
  slug: TwoFactorAuthenticationBasicsValidationSlug,
}
