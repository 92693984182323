import React from 'react'
import type { RouteProps } from 'react-router-dom'
import { Route, Routes } from 'react-router-dom'

import { Four04Page } from '../resources/index.ts'
import { PrivacyPage } from './pages/Privacy/index.ts'

export const PrivacyRoutes: React.FC<RouteProps> = (props) => {
  return (
    <Routes {...props}>
      <Route element={<PrivacyPage />} path="/" />
      <Route path="*" element={<Four04Page />} />
    </Routes>
  )
}
