import {
  NotificationsActiveRounded,
  NotificationsOffRounded,
  NotificationsPausedRounded,
} from '@mui/icons-material'
import type { IconButtonProps } from '@mui/material'
import { IconButton } from '@mui/material'
import { RotationAnimation } from '@xylabs/react-animation'
import { FlexCol } from '@xylabs/react-flexbox'
import { usePromise } from '@xylabs/react-promise'
import React, { useMemo, useState } from 'react'

import type { UserContextType } from '#contexts'
import { useUser } from '#contexts'
import {
  getBrowserNotificationToken, registerBrowserToken, requestNotificationPermission,
} from '#lib'

import type { NotificationSettings } from './NotificationSettingsStore/index.ts'
import {
  latestNotificationSettingsPayload, NotificationSettingsSchema, notificationSettingsStorageArchivist,
} from './NotificationSettingsStore/index.ts'

interface DefaultNotificationsIconColors {
  defaultDisabledColor?: IconButtonProps['color']
  defaultEnabledColor?: IconButtonProps['color']
  enabled?: boolean
}

export interface NotificationsIconButtonProps extends IconButtonProps, DefaultNotificationsIconColors {
  notificationsEnabled?: boolean
  toggleMode?: () => void
}

const requestPermission = async (user: UserContextType) => {
  try {
    // Get the user's permission to receive notifications
    await requestNotificationPermission()
    // If we have a user and know their email
    const email = user?.user?.email
    if (email) {
      // Get the token for the user's browser to send them notifications
      const browserToken = await getBrowserNotificationToken()

      // Register the token to the user's email to allow sending them push notifications
      await registerBrowserToken(browserToken, email)
      return true
    }
  } catch (error) {
    console.error('Error getting token:', error)
    return false
  }
}

export const NotificationsIconButton: React.FC<NotificationsIconButtonProps> = ({
  notificationsEnabled,
  defaultDisabledColor,
  defaultEnabledColor,
  enabled,
  toggleMode,
  ...props
}) => {
  const disabledIconColor = defaultDisabledColor ?? 'inherit'
  const enabledIconColor = defaultEnabledColor ?? 'inherit'
  const [iconColor, setIconColor] = useState<IconButtonProps['color']>(notificationsEnabled ? enabledIconColor : disabledIconColor)
  const [iconColorIsSet, setIconColorIsSet] = useState(enabled)

  const handleHover = () => {
    setIconColor(() => {
      if (notificationsEnabled) {
        return iconColorIsSet ? enabledIconColor : 'info'
      } else {
        return iconColorIsSet ? disabledIconColor : 'warning'
      }
    })
    setIconColorIsSet(!iconColorIsSet)
  }

  const handleNotificationsChange = () => {
    toggleMode?.()
  }

  return (
    <FlexCol>
      <RotationAnimation rotation={20}>
        <IconButton color={iconColor} onClick={handleNotificationsChange} onMouseEnter={() => handleHover()} onMouseLeave={() => handleHover()} {...props}>
          {notificationsEnabled === undefined
            ? <NotificationsPausedRounded sx={{ opacity: 0.25 }} />
            : notificationsEnabled
              ? <NotificationsActiveRounded />
              : <NotificationsOffRounded />}
        </IconButton>
      </RotationAnimation>
    </FlexCol>
  )
}

export interface NotificationsToggleIconButtonProps extends DefaultNotificationsIconColors, IconButtonProps {}

export const NotificationsToggleIconButton: React.FC<NotificationsToggleIconButtonProps> = (props) => {
  const [notificationsEnabled, setNotificationsEnabled] = useState<boolean | undefined>()

  const [notificationsEnabledSetting] = usePromise(async () => {
    if (notificationsEnabled === undefined) {
      const hasPermission = Notification.permission === 'granted'
      if (hasPermission) {
        const token = await getBrowserNotificationToken()
        const latestSettings = await latestNotificationSettingsPayload(token)
        // console.log('latestSettings', latestSettings)
        const settings = latestSettings ?? { enabled: true, token }
        return settings.enabled
      } else {
        return false
      }
    }
  }, [notificationsEnabled])

  useMemo(() => {
    if (notificationsEnabled === undefined && notificationsEnabledSetting !== undefined) {
      setNotificationsEnabled(notificationsEnabledSetting)
    }
  }, [notificationsEnabledSetting, notificationsEnabled, setNotificationsEnabled])

  const user = useUser()

  const setTokenNotificationsEnabled = async (enabled: boolean) => {
    // console.log('setTokenNotificationsEnabled', enabled)
    const archivist = await notificationSettingsStorageArchivist()
    const token = await getBrowserNotificationToken()
    const payload: NotificationSettings = {
      enabled, token, schema: NotificationSettingsSchema,
    }
    await archivist.insert([payload])
    // console.log('setTokenNotificationsEnabled.insert', inserted)
    setNotificationsEnabled(enabled)
  }

  const toggleMode = async () => {
    if (notificationsEnabled) {
      await setTokenNotificationsEnabled(false)
    } else {
      if (Notification.permission === 'granted') {
        await setTokenNotificationsEnabled(true)
      } else if (Notification.permission === 'denied') {
        alert('You have blocked notifications. Please enable them in your browser settings.')
      } else {
        const success = await requestPermission(user)
        if (success) {
          await setTokenNotificationsEnabled(true)
        }
      }
    }
  }

  return <NotificationsIconButton notificationsEnabled={notificationsEnabled} toggleMode={toggleMode} {...props} />
}
