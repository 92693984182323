import type { ChipProps, Theme } from '@mui/material'
import {
  Box, Chip, Icon,
  styled,
} from '@mui/material'
import React from 'react'

import { DataismIcon } from '../../img/index.ts'

export interface DatumChipProps extends ChipProps {
  totalDatum?: number
}

export const DatumChip: React.FC<DatumChipProps> = ({ totalDatum, ...props }) => {
  return (
    <StyledChip
      label={(
        <Box
          component="span"
          sx={{
            alignItems: 'center', display: 'inline-flex', gap: 0.5,
          }}
        >
          <Box component="span">{`+ ${totalDatum}`}</Box>
          <Icon sx={{ alignItems: 'center', display: 'inline-flex' }}>
            <DataismIcon color="warning" sx={{ filter: 'grayscale(50%)', height: (theme: Theme) => theme.spacing(1.75) }} />
          </Icon>
        </Box>
      )}
      size="small"
      {...props}
    />
  )
}

export const StyledChip = styled(Chip, { name: 'StyledChip' })(({ theme }) => ({
  '& .MuiChip-label': {
    color: theme.palette.warning.main,
    display: 'inline-flex',
    fontWeight: '700',
    gap: theme.spacing(0.5),
    alignItems: 'center',
    paddingRight: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.5),
  },
  'height': 'auto',
  'paddingBottom': theme.spacing(0.25),
  'paddingTop': theme.spacing(0.25),
}))
