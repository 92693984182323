import {
  Backdrop,
  SpeedDial, SpeedDialIcon, type SpeedDialProps, styled,
} from '@mui/material'
import React, { useState } from 'react'

export interface SpeedDialInnerProps extends SpeedDialProps {}

export const SpeedDialInner: React.FC<SpeedDialInnerProps> = ({ children, ...props }) => {
  const [openSpeedDial, setOpenSpeedDial] = useState(false)
  const handleOpenSpeedDial = () => setOpenSpeedDial(true)
  const handleCloseSpeedDial = () => setOpenSpeedDial(false)

  return (
    <>
      <Backdrop open={openSpeedDial} onClick={handleCloseSpeedDial} />
      <StyledSpeedDial
        sx={{
          position: 'fixed', bottom: 16, right: 16,
        }}
        icon={<SpeedDialIcon />}
        onClose={handleCloseSpeedDial}
        onOpen={handleOpenSpeedDial}
        open={openSpeedDial}
        {...props}
      >
        {children}
      </StyledSpeedDial>
    </>
  )
}

const StyledSpeedDial = styled(SpeedDial, { name: 'StyledSpeedDial' })(
  () => (
    // NOTE: This action tooltip label can only be targeted from the SpeedDial component and NOT the SpeedDialAction component
    // It seems MUI renders the tooltip as a sibling of the SpeedDialAction component and not a child like you might expect.
    { '& .MuiSpeedDialAction-staticTooltipLabel': { whiteSpace: 'nowrap' } }
  ),
)
