import type { FirebaseError } from '@firebase/app'
import type { Auth } from '@firebase/auth'
import { GoogleAuthProvider, signInWithCredential } from '@firebase/auth'

/**
 * Signs in a user with their email and password.
 * @param auth The Auth context to use
 * @param credential Credential from Google One Tap
 */
export const loginWithGoogleOneTap = async (auth: Auth, jwtIdToken: string) => {
  try {
    // TODO: should we prevent login here or replace it?
    if (!auth.currentUser) {
      const credential = GoogleAuthProvider.credential(jwtIdToken)
      await signInWithCredential(auth, credential)
    }
  } catch (e) {
    const error = e as FirebaseError
    const errorCode = error.code
    const errorMessage = error.message

    switch (errorCode) {
      default: {
        console.error(errorCode, errorMessage)
        throw new Error('An error occurred while logging in')
      }
    }
  }
}
