import { IterableAxios, iterableUsersUpdateUrl } from '../constants.ts'
import type { User } from '../types.ts'

export type TrackUserUpdateEvent = User & {
  createNewFields?: true
  dataFields?: Record<string, string>
  email?: string
  mergeNestedObjects?: true
  preferUserId?: true
}

export const updateUser = async (event: TrackUserUpdateEvent) => {
  try {
    return await IterableAxios.post(iterableUsersUpdateUrl, event)
  } catch (error) {
    console.error(error)
    globalThis.rollbar?.error(error as Error)
  }
}
