import type { BusyBoxProps } from '@xylabs/react-flexbox'
import React from 'react'

import { DataismBasicHero, JoinWaitList } from '#components'

export const RequestEarlyAccessHero: React.FC<BusyBoxProps> = () => {
  return (
    <DataismBasicHero
      title="Request Early Access"
      desc="Dataism.org's Dashboard and Dataist Journey system is not yet available to everyone.
      Use the form below to join the waitlist for early access, and you will receive an email with an invite when your access is available."
      overflow="hidden"
      extras={<JoinWaitList />}
    />
  )
}
