import { assertEx } from '@xylabs/sdk-js'

import { IterableAxios, iterableTrackUrl } from '../constants.ts'
import type { User } from '../types.ts'

export type TrackEvent = User & {
  campaignId?: number
  createNewFields?: boolean
  createdAt?: number
  dataFields?: Record<string, unknown>
  eventName: string
  templateId?: number
}

export const iterableTrackEvent = async (event: TrackEvent) => {
  try {
    const result = await IterableAxios.post(iterableTrackUrl, event)
    assertEx(result.status === 200, () => `iterableTrackEvent failed: ${result.status}`)
  } catch (error) {
    console.error(error)
    globalThis.rollbar?.error(error as Error)
  }
}
