import type { StackProps } from '@mui/material'
import { Stack } from '@mui/material'
import { LinkEx } from '@xylabs/react-link'
import React from 'react'

interface socialLinksDataProps {
  href: string
  title: string
}

const footerLinks: socialLinksDataProps[] = [
  {
    href: 'https://xylabs.com/',
    title: 'XY Labs, Inc.',
  },
  {
    href: 'https://xyo.network/',
    title: 'XYO Foundation',
  },
  {
    href: 'https://dataism.org/privacy/',
    title: 'Privacy',
  },
  {
    href: 'https://xylabs.com/terms/',
    title: 'Terms',
  },
  {
    href: 'https://xylabs.com/jobs',
    title: 'Careers',
  },
]

export const CompanyFooterLinks: React.FC<StackProps> = (props) => {
  return (
    <Stack gap={1} flexWrap="wrap" justifyContent="center" flexDirection="row" {...props}>
      {footerLinks.map((social) => {
        return (
          <LinkEx key={`footer-social-link-${social.title}`} href={social.href} target="_blank">
            {social.title}
          </LinkEx>
        )
      })}
    </Stack>
  )
}
