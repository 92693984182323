import { Divider, Typography } from '@mui/material'
import type { BasePageProps } from '@xylabs/react-common'
import { BasePage, RedirectWithQuery } from '@xylabs/react-common'
import { CookieConsent } from '@xylabs/react-cookie-consent'
import type { FlexBoxProps } from '@xylabs/react-flexbox'
import { FlexCol, FlexRow } from '@xylabs/react-flexbox'
import { useCheckUniversalRedirect } from '@xylabs/react-hooks'
import { useUserEvents } from '@xylabs/react-pixel'
import { ScrollToTop } from '@xylabs/react-scroll-to-top'
import { isLocalhost } from '@xylabs/react-shared'
import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

import { DataismSpeedDial, GoogleOneTapLogin } from '#components'

import { useAuth, useSettings } from '../contexts/index.ts'
import { DataismFooter } from './Footer/index.ts'
import { DataismAppBar } from './Header/index.ts'

export interface DataismBasePageProps extends BasePageProps {
  colorfulBackground?: boolean
  devOnly?: string | boolean
  devOnlyTo?: string | boolean
  extraAppBarSpace?: boolean
  googleOneTap?: boolean
  localAsDev?: boolean
  name?: string
}

interface ColorfulBackgroundProps extends FlexBoxProps {
  enabled?: boolean
}

const ColorfulBackground: React.FC<ColorfulBackgroundProps> = ({
  enabled, children, ...props
}) => {
  return enabled
    ? (
        <FlexCol
          width="100%"
          alignItems="stretch"
          sx={{
            backgroundPosition: 'top',
            backgroundRepeat: 'repeat-y',
            backgroundSize: 'cover',
          }}
          {...props}
        >
          {children}
        </FlexCol>
      )
    : <>{children}</>
}

export const DataismBasePage: React.FC<DataismBasePageProps> = ({
  devOnly = false,
  devOnlyTo = false,
  extraAppBarSpace = false,
  localAsDev = false,
  googleOneTap = false,
  children,
  title,
  colorfulBackground = false,
  ...props
}) => {
  const { developerMode } = useSettings()

  const userEvents = useUserEvents()
  const location = useLocation()
  const auth = useAuth()

  useCheckUniversalRedirect()

  const devRequirementPassed = !(devOnly || devOnlyTo) || developerMode || (localAsDev && isLocalhost)

  useMemo(() => {
    userEvents?.viewContent({ name: title, path: location.pathname }).catch(console.error)
  }, [userEvents])

  return (
    <BasePage
      title={title}
      appBar={(
        <FlexCol>
          <DataismAppBar position="fixed" style={{ left: 0, right: 0 }} />
          <Divider flexItem variant="fullWidth" />
        </FlexCol>
      )}
      appFooter={(
        <DataismFooter />
      )}
      cookieConsent={<CookieConsent />}
      {...props}
    >
      <FlexCol justifyContent="flex-start" alignItems="stretch" minHeight="calc(100vh - 40px)" sx={{ mt: extraAppBarSpace ? '65px' : 0 }}>
        <ScrollToTop />
        {/* This is here to provide spacing under fixed header */}
        <Helmet>
          <title>{`Dataism: ${title ?? 'Home'}`}</title>
        </Helmet>
        {devOnly && devRequirementPassed
          ? (
              <FlexRow margin={1}>
                <Typography variant="body1">
                  Important: This page is a Developer Only page. It is possible that some information may not be correct.
                </Typography>
              </FlexRow>
            )
          : null}
        <FlexRow>{devOnly && !devRequirementPassed ? <RedirectWithQuery to="/" /> : null}</FlexRow>
        <ColorfulBackground enabled={colorfulBackground}>{children}</ColorfulBackground>
      </FlexCol>
      <GoogleOneTapLogin auth={auth} enabled={googleOneTap} clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID} />
      <DataismSpeedDial />
    </BasePage>
  )
}
