import type { Payload } from '@xyo-network/payload-model'
import { isPayloadOfSchemaTypeWithMeta } from '@xyo-network/payload-model'

import type { Sluggish } from '../../Sluggish.ts'
import { JourneySchema } from '../Journey.ts'
import type { TaskRequirementBase } from './requirement/index.ts'
import type { TaskResource } from './Resource.ts'
import type { TaskRewardBase } from './reward/index.ts'
import type { JourneyTaskMetadata } from './Types.ts'
import type { TaskValidationBase } from './validation/index.ts'

export const JourneyTaskSchema = 'org.dataism.journey.task' as const
export type JourneyTaskSchema = typeof JourneyTaskSchema

export interface JourneyTaskSetValidatedRequirementFields {
  /** Requirement that must be completed (i.e. Quiz) */
  requirement: TaskRequirementBase
  /** Title of the validated requirement */
  title?: string
  /** Validation used to judge if a requirement was completed successfully */
  validation: TaskValidationBase
}

export interface JourneyTaskSetFields {
  /** Reward for successfully completing the requirement */
  reward: TaskRewardBase[]
  /** A set of requirements to complete and how to validate them */
  validatedRequirements: JourneyTaskSetValidatedRequirementFields[]
}

export interface JourneyTaskFields extends Sluggish {
  description: string
  /** metadata about the Task to help find similar Tasks */
  metadata: JourneyTaskMetadata[]
  /** Resources required to complete the task */
  resource: TaskResource[]
  /** Tasks associated with the journey */
  taskSet: JourneyTaskSetFields
  timeToComplete: number
  title: string
}

export type JourneyTask = Payload<JourneyTaskFields, JourneyTaskSchema>
export const isJourneyTask = isPayloadOfSchemaTypeWithMeta<JourneyTask>(JourneySchema)
