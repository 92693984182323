import React, { useEffect, useState } from 'react'

import { PersonaScoreChart } from '../Recharts/index.ts'

export type PersonaRadarChartProps = {
  initialResultData: {
    fullMark: number
    persona: 'Nova' | 'Vex' | 'Rook' | 'Lux' | 'Aegis' | 'Echo' | 'Myca' | 'Cass'
    value: number
  }[]
}

export const ChangingRadarChart: React.FC<PersonaRadarChartProps> = ({ initialResultData }) => {
  const [resultData, setResultData] = useState(initialResultData)

  const generateRandomResultData = () => {
    return resultData.map(item => ({
      ...item,
      value: Math.floor(Math.random() * item.fullMark) + 1,
    }))
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setResultData(generateRandomResultData())
    }, 2000)

    return () => clearInterval(interval)
  }, [resultData])

  const sortedResultData = resultData.sort((a, b) => a.persona.localeCompare(b.persona))

  return (
    <div style={{ width: '100%', height: 400 }}>
      <PersonaScoreChart data={sortedResultData} />
    </div>
  )
}
