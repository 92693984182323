import type {
  IQueryBuilder, SortDirection, WhereFilterOperator,
} from '../../interface/index.ts'

export class MemoryQueryBuilder<TData> implements IQueryBuilder<TData, (item: TData[]) => TData[]> {
  private filters: Array<(item: TData) => boolean> = []
  private resultLimit: number | null = null
  private resultOffset: number = 0
  private sortDirection: SortDirection = 'asc'
  private sortField: keyof TData | null = null

  build(): (item: TData[]) => TData[] {
    return (items: TData[]) => this.execute(items)
  }

  limit(count: number): this {
    this.resultLimit = count
    return this
  }

  offset(count: number): this {
    this.resultOffset = count
    return this
  }

  orderBy(field: keyof TData, direction: SortDirection = 'asc'): this {
    this.sortField = field
    this.sortDirection = direction
    return this
  }

  where<K extends keyof TData>(field: K, operator: WhereFilterOperator, value: TData[K]): this {
    this.filters.push((item) => {
      switch (operator) {
        case '==': { return item[field] === value
        }
        case '!=': { return item[field] !== value
        }
        case '>': { return item[field] > value
        }
        case '>=': { return item[field] >= value
        }
        case '<': { return item[field] < value
        }
        case '<=': { return item[field] <= value
        }
        default: { return false
        }
      }
    })
    return this
  }

  /**
     * Apply the built query to an array of items
     * @param items The items to query
     * @returns Filtered and sorted array of items
     */
  private execute(items: TData[]): TData[] {
    let results = items
    for (const filter of this.filters) {
      results = items.filter(filter)
    }

    // Apply sorting if specified
    if (this.sortField) {
      results.sort((a, b) => {
        const aValue = a[this.sortField as keyof TData]
        const bValue = b[this.sortField as keyof TData]
        if (aValue < bValue) return this.sortDirection === 'asc' ? -1 : 1
        if (aValue > bValue) return this.sortDirection === 'asc' ? 1 : -1
        return 0
      })
    }

    // Apply offset and limit
    if (this.resultOffset) {
      results = results.slice(this.resultOffset)
    }
    if (this.resultLimit !== null) {
      results = results.slice(0, this.resultLimit)
    }

    return results
  }
}
