import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material'
import type { StackProps } from '@mui/material'
import {
  InputAdornment, Stack, TextField, Typography,
} from '@mui/material'
import { ButtonEx } from '@xylabs/react-button'
import { FlexCol } from '@xylabs/react-flexbox'
import { assertEx } from '@xylabs/sdk-js'
import { default as emailRegEx } from 'email-regex-safe'
import React, { useState } from 'react'

import { IterableEvents, iterableTrackEvent } from '#lib'

export interface JoinWaitListProps extends StackProps {}

export const JoinWaitList: React.FC<JoinWaitListProps> = (props) => {
  const [customerEmail, setCustomerEmail] = useState<string>('')
  const [isValidEmail, setIsValidEmail] = useState<boolean | null>(null)
  const [busy, setBusy] = useState(false)
  const [completed, setCompleted] = useState(false)

  const validEmailRegex = (email: string) => {
    return emailRegEx({ exact: true }).test(email)
  }

  const emailValidate = (value: string) => {
    if (validEmailRegex(value)) {
      setIsValidEmail(true)
    } else {
      setIsValidEmail(false)
    }
  }

  const handleChange = (email: string) => {
    if (email.length > 0) {
      setCustomerEmail(email)
      emailValidate(email)
    } else {
      setCustomerEmail('')
      emailValidate(email)
    }
  }

  const onJoin = () => {
    setBusy(true)
    iterableTrackEvent({ eventName: IterableEvents.waitlistSignup, email: assertEx(customerEmail, () => 'No email provided') }).then(() => {
      setCompleted(true)
      setBusy(false)
    }).catch(() => {
      setBusy(false)
      console.error('Failed to signup for waitlist')
    })
  }

  if (completed) {
    return (
      <FlexCol busy={busy} gap={1} {...props}>
        <Typography>Thank you for signing up!</Typography>
      </FlexCol>
    )
  }

  return (
    <Stack gap={1} sx={{ flexDirection: { xs: 'column', md: 'row' } }} {...props}>
      <TextField
        type="email"
        variant="outlined"
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment position="end">
                {isValidEmail === null && <CheckCircleOutline sx={{ visibility: 'hidden' }} />}
                {isValidEmail === true && <CheckCircleOutline color="success" fontSize="medium" />}
                {isValidEmail === false && <ErrorOutline color="error" fontSize="medium" />}
              </InputAdornment>
            ),
          },
        }}
        size="small"
        value={customerEmail ?? ''}
        onChange={event => handleChange?.(event.target.value)}
      />
      <ButtonEx busy={busy} size="small" variant="contained" onClick={onJoin} disabled={!isValidEmail}>Join Waitlist</ButtonEx>
    </Stack>
  )
}
