import type { PayloadWithSources } from '@xyo-network/payload-model'
import {
  isPayloadOfSchemaType,
  isPayloadOfSchemaTypeWithMeta,
  isPayloadOfSchemaTypeWithSources,
} from '@xyo-network/payload-model'

export const NotificationSettingsSchema = 'network.xyo.browser.notification.settings' as const
export type NotificationSettingsSchema = typeof NotificationSettingsSchema

/**
 * The fields of a NotificationSettings payload
 */
export interface NotificationSettingsFields {
  /**
   * Are notifications for this token enabled
  */
  enabled?: boolean
  /**
   * Notification Token
   */
  token?: string
}

/**
 * The fields of a Utm payload
 */
export type NotificationSettings = PayloadWithSources<NotificationSettingsFields, NotificationSettingsSchema>

/**
 * Identity function for determining if an object is a Utm payload
 */
export const isNotificationSettings = isPayloadOfSchemaType<NotificationSettings>(NotificationSettingsSchema)

/**
 * Identity function for determining if an object is a Utm payload with sources
 */
export const isNotificationSettingsWithSources = isPayloadOfSchemaTypeWithSources<NotificationSettings>(NotificationSettingsSchema)

/**
 * Identity function for determining if an object is a Utm payload with meta
 */
export const isNotificationSettingsWithMeta = isPayloadOfSchemaTypeWithMeta<NotificationSettings>(NotificationSettingsSchema)
