import type { ReactElement } from 'react'
import React from 'react'

import { firestore } from '#firebase'

import { StorageContext } from './Context.ts'

interface StorageProviderProps {
  children?: ReactElement[] | ReactElement | undefined
}

export const StorageProvider: React.FC<StorageProviderProps> = ({ children }) => {
  return (
    <StorageContext.Provider value={firestore}>
      {children}
    </StorageContext.Provider>
  )
}
