import type { Auth } from '@firebase/auth'
import { GithubAuthProvider } from '@firebase/auth'

import { linkWithProvider, loginWithProvider } from '../lib/index.ts'

/**
 * Signs in a user with their Github account
 */
export const loginWithGithub = (auth: Auth) => loginWithProvider(auth, new GithubAuthProvider())

/**
 * Links a user with their Github account
 * @param auth The Firebase Auth instance
 */
export const linkWithGithub = (auth: Auth) => linkWithProvider(auth, new GithubAuthProvider())
