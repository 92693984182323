import { Divider } from '@mui/material'
import React from 'react'

import { DataismBasePage } from '#chrome'

import { RequestEarlyAccessHero } from './Hero.tsx'

export const RequestEarlyAccessPage: React.FC = () => {
  return (
    <DataismBasePage title="Information">
      <RequestEarlyAccessHero />
      <Divider />
    </DataismBasePage>
  )
}
