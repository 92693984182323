import type { SvgIconProps } from '@mui/material'
import type { ReactNode } from 'react'
import React from 'react'

import {
  AegisIcon,
  CassIcon,
  EchoIcon,
  LuxIcon,
  MycaIcon,
  NovaIcon, RookIcon, VexIcon,
} from '#img'

import type { PersonaName } from './NameData.tsx'

export type PersonaIcon = {
  IconComponent?: React.FC<SvgIconProps>
  icon: ReactNode
  iconLarge: ReactNode
}

export const personaIconsData: Record<PersonaName, PersonaIcon> = {
  nova: {
    IconComponent: NovaIcon,
    icon: (
      <NovaIcon style={{
        height: '500px', width: '500px', color: 'inherit',
      }}
      />
    ),
    iconLarge: (
      <NovaIcon style={{
        height: '800px', width: '800px', color: 'inherit',
      }}
      />
    ),
  },
  vex: {
    IconComponent: VexIcon,
    icon: (
      <VexIcon style={{
        height: '500px', width: '500px', color: 'inherit',
      }}
      />
    ),
    iconLarge: (
      <VexIcon style={{
        height: '800px', width: '800px', color: 'inherit',
      }}
      />
    ),
  },
  rook: {
    IconComponent: RookIcon,
    icon: (
      <RookIcon style={{
        height: '500px', width: '500px', color: 'inherit',
      }}
      />
    ),
    iconLarge: (
      <RookIcon style={{
        height: '800px', width: '800px', color: 'inherit',
      }}
      />
    ),
  },
  lux: {
    IconComponent: LuxIcon,
    icon: (
      <LuxIcon style={{
        height: '500px', width: '500px', color: 'inherit',
      }}
      />
    ),
    iconLarge: (
      <LuxIcon style={{
        height: '800px', width: '800px', color: 'inherit',
      }}
      />
    ),
  },
  aegis: {
    IconComponent: AegisIcon,
    icon: (
      <AegisIcon style={{
        height: '500px', width: '500px', color: 'inherit',
      }}
      />
    ),
    iconLarge: (
      <AegisIcon style={{
        height: '800px', width: '800px', color: 'inherit',
      }}
      />
    ),
  },
  echo: {
    IconComponent: EchoIcon,
    icon: (
      <EchoIcon style={{
        height: '500px', width: '500px', color: 'inherit',
      }}
      />
    ),
    iconLarge: (
      <EchoIcon style={{
        height: '800px', width: '800px', color: 'inherit',
      }}
      />
    ),
  },
  myca: {
    IconComponent: MycaIcon,
    icon: (
      <MycaIcon style={{
        height: '500px', width: '500px', color: 'inherit',
      }}
      />
    ),
    iconLarge: (
      <MycaIcon style={{
        height: '800px', width: '800px', color: 'inherit',
      }}
      />
    ),
  },
  cass: {
    IconComponent: CassIcon,
    icon: (
      <CassIcon style={{
        height: '500px', width: '500px', color: 'inherit',
      }}
      />
    ),
    iconLarge: (
      <CassIcon style={{
        height: '800px', width: '800px', color: 'inherit',
      }}
      />
    ),
  },
}
