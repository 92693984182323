import { useTheme } from '@mui/material'
import { type FlexBoxProps, FlexCol } from '@xylabs/react-flexbox'
import React from 'react'

export const FullPageResponsiveBox: React.FC<FlexBoxProps> = ({
  children, sx, ...props
}) => {
  const theme = useTheme()
  return (
    <FlexCol
      sx={{
        alignItems: { md: 'center', xs: 'flex-start' },
        paddingY: { lg: theme.spacing(10), xs: theme.spacing(8) },
        ...sx,
      }}
      {...props}
    >
      {children}
    </FlexCol>
  )
}
