// import type { User } from '@firebase/auth'
import type {
  CollectionReference, DocumentData, DocumentReference, Firestore,
  QuerySnapshot,
} from '@firebase/firestore'
import {
  collection, deleteDoc, doc, getDoc, getDocs, setDoc, updateDoc,
} from '@firebase/firestore'

import { firestore as localFireStore } from '#firebase'

import type { IRepository } from '../../interface/index.ts'
import type { FirestoreIdPredicate } from './IdPredicate.ts'
import { FirestoreQueryBuilder } from './QueryBuilder.ts'

export class FirestoreRepository<TData extends DocumentData = DocumentData> implements IRepository<TData, FirestoreQueryBuilder<TData>> {
  private readonly collectionName: string
  private readonly firestore: Firestore
  private readonly idPredicate: FirestoreIdPredicate<TData>

  constructor(collectionName: string, idPredicate: FirestoreIdPredicate<TData>, firestore: Firestore = localFireStore) {
    this.firestore = firestore
    this.collectionName = collectionName
    this.idPredicate = idPredicate
  }

  private get collectionRef(): CollectionReference<DocumentData> {
    return collection(this.firestore, this.collectionName)
  }

  async add(entity: TData): Promise<void> {
    const id = this.idPredicate(entity)
    const document = doc(this.firestore, this.collectionName, id)
    await setDoc(document, entity)
  }

  async count(): Promise<number> {
    const snapshot = await getDocs(this.collectionRef)
    return snapshot.size
  }

  createQueryBuilder(): FirestoreQueryBuilder<TData> {
    return new FirestoreQueryBuilder(this.collectionRef)
  }

  async delete(entity: TData): Promise<void> {
    const id = this.idPredicate(entity)
    await this.deleteById(id)
  }

  async deleteById(id: string): Promise<void> {
    const document = doc(this.firestore, this.collectionName, id)
    await deleteDoc(document)
  }

  async find(queryBuilder: FirestoreQueryBuilder<TData>): Promise<TData[]> {
    const allItems = await getDocs(queryBuilder.build()) as QuerySnapshot<TData, TData>
    return allItems.docs.map(doc => doc.data())
  }

  async getAll(): Promise<TData[]> {
    const snapshot = await getDocs(this.collectionRef) as QuerySnapshot<TData, TData>
    return snapshot.docs.map(doc => doc.data())
  }

  async getById(id: string): Promise<TData | null> {
    const document = doc(this.firestore, this.collectionName, id) as DocumentReference<TData, TData>
    const snapshot = await getDoc(document)
    return snapshot.exists() ? snapshot.data() : null
  }

  async update(entity: TData): Promise<void> {
    const id = this.idPredicate(entity)
    const document = doc(this.firestore, this.collectionName, id)
    await updateDoc(document, entity)
  }
}
