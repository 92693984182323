import { Slide } from '@mui/material'
import type { TransitionProps } from '@mui/material/transitions'
import React, { forwardRef } from 'react'

export const SlideTransition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="right" ref={ref} {...props} />
})
