import type { BadgeTaskReward } from '#models'
import { BadgeTaskRewardSchema } from '#models'

export const PillarsOfDataismBadge: BadgeTaskReward = {
  schema: BadgeTaskRewardSchema,
  badgeId: 'badge-id',
  slug: 'slug-badge-dataist-beginnings',
  rewardType: 'badge',
  title: 'Dataist Beginnings Badge',
}
