import { type JourneyTask, JourneyTaskSchema } from '#models'

import { OneHundredDatum } from '../shared/index.ts'
import { PillarsOfDataismBadge } from './PillarsOfDataismBadge.ts'
import { PillarsOfDataismQuizData } from './PillarsOfDataismQuizData.ts'
import { PillarsOfDataismValidation } from './PillarsOfDataismValidation.ts'
import { PillarsOfDataismQuizTaskSlug, PillarsOfDataismReadingTaskSlug } from './Slugs.ts'

export const PillarsOfDataismReadingTaskData: JourneyTask = {
  description: 'Learn First',
  metadata: ['reading'],
  resource: [{
    title: 'Pillars of Dataism',
    url: 'https://dataism.org/pillars?from_quiz=1',
    schema: 'org.dataism.journey.task.resource',
    slug: 'slug-pillars-of-dataism-url',
  }],
  schema: JourneyTaskSchema,
  slug: PillarsOfDataismReadingTaskSlug,
  timeToComplete: (60 * 2) * 1000,
  title: 'Read to Learn',
  taskSet: {
    reward: [],
    validatedRequirements: [],
  },
}

export const PillarsOfDataismQuizTaskData: JourneyTask = {
  description: 'Test Your Knowledge',
  metadata: ['quiz'],
  resource: [],
  schema: JourneyTaskSchema,
  slug: PillarsOfDataismQuizTaskSlug,
  timeToComplete: (60 * 2) * 1000,
  title: 'Take a Quiz',
  taskSet: {
    reward: [PillarsOfDataismBadge, OneHundredDatum],
    validatedRequirements: [{ requirement: PillarsOfDataismQuizData, validation: PillarsOfDataismValidation }],
  },
}
