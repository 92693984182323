import type { FirebaseError } from '@firebase/app'
import type { OAuthCredential, User } from '@firebase/auth'
import {
  AuthErrorCodes,
  getRedirectResult, GithubAuthProvider, GoogleAuthProvider,
  linkWithCredential,
  onAuthStateChanged, ProviderId, TwitterAuthProvider,
} from '@firebase/auth'
import { usePromise } from '@xylabs/react-promise'
import type { ReactElement } from 'react'
import React, {
  useCallback,
  useEffect, useMemo, useState,
} from 'react'

import {
  IterableEvents, iterableTrackEvent, updateUser,
} from '#lib'
import { useDataismUserEvents } from '#user-events'

import { useAuth } from '../Auth/index.ts'
import { UserContext } from './Context.ts'

interface UserProviderProps {
  children?: ReactElement[] | ReactElement | undefined
}

type ProviderIdValue = typeof ProviderId[keyof typeof ProviderId]
type WithProviderId = { providerId?: ProviderIdValue }

/**
 * UserProvider component that will provide the user and loading state to its children
 */
export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const auth = useAuth()
  // The current user
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)
  const userEvents = useDataismUserEvents()

  const dispatchAccountLoginEvent = useCallback(async (userId: string, emailAddress: string | null, providerId: string | null) => {
    const email = emailAddress ?? undefined
    const thirdParty = providerId ?? undefined

    // Ensure user is successfully added to iterable
    updateUser({ email, userId }).catch(error => globalThis.rollbar?.error(error))
    // Track the account login event in iterable
    iterableTrackEvent({
      eventName: IterableEvents.accountLogin,
      email: email ?? 'No email...Anonymous User?',
    }).catch(error => globalThis.rollbar?.error(error))

    await userEvents.accountLogin({
      userId, email, thirdParty,
    })
  }, [userEvents])

  useEffect(() => {
    // Set up the listener for user changes
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      // Update the current user when the user changes
      setUser(currentUser)
      // Once we've resolved the first user state (this can even be
      // no user), set loading to false
      setLoading(false)
      // If there was a user, this is a login event
      if (currentUser) {
        const {
          email, uid, providerId,
        } = currentUser
        dispatchAccountLoginEvent(uid, email, providerId).catch((err) => {
          console.error(err)
          globalThis.rollbar?.error(err)
        })
      }
    })
    // Return a callback to make sure we unsubscribe from listening
    // to user changes when the component unmounts to prevent memory
    // leaks
    return () => unsubscribe()
  }, [])

  usePromise(async () => {
    try {
      // Try to get the redirect result
      const result = await getRedirectResult(auth)
      // If we have a user in the result
      if (result?.user) {
        // Set the user in the state
        setUser(result.user)
      }
    } catch (e) {
      console.error('Error during redirect sign-in:', e)
      const firebaseError = e as FirebaseError
      const errorCode = firebaseError?.code
      const errorMessage = firebaseError?.message
      console.error(errorCode, errorMessage)
      if (errorCode === AuthErrorCodes.NEED_CONFIRMATION) {
        if (user) {
          const provider = (firebaseError?.customData?._tokenResponse as WithProviderId)?.providerId
          if (provider) {
            let credential: OAuthCredential | null = null
            switch (provider) {
              case ProviderId.GITHUB: {
                credential = GithubAuthProvider.credentialFromError(firebaseError)
                break
              }
              case ProviderId.GOOGLE: {
                credential = GoogleAuthProvider.credentialFromError(firebaseError)
                break
              }
              case ProviderId.TWITTER: {
                credential = TwitterAuthProvider.credentialFromError(firebaseError)
                break
              }
              default: {
                console.error(`Unknown provider: ${provider}`)
                break
              }
            }
            if (credential) {
              const updated = await linkWithCredential(user, credential)
              setUser(updated.user)
            }
          }
        } else {
          // TODO: Throw a Dialog that says: "Looks like this account
          // already exists with a different provider please log in
          // with that provider first before merging accounts"
          console.error('User needs to log in with the provider that created the account')
          setError(firebaseError)
        }
      }
    }
  }, [auth, user, userEvents])

  const value = useMemo(() => ({
    error, user, loading,
  }), [user, loading])
  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  )
}
