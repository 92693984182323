import { StorageArchivist, StorageArchivistConfigSchema } from '@xyo-network/archivist-storage'

import type { NotificationSettings } from './NotificationPayload.ts'
import { isNotificationSettings } from './NotificationPayload.ts'

const STORAGE_NAME_SPACE = 'notificationSettings' as const

let notificationSettingsArchivist: StorageArchivist | undefined

/**
 * Find or create a single instance of the NotificationSettingsStorageArchivist
 * @returns Archivist instance
 */
export const notificationSettingsStorageArchivist = async () => {
  if (notificationSettingsArchivist) {
    return notificationSettingsArchivist
  } else {
    notificationSettingsArchivist = await StorageArchivist.create({
      account: 'random',
      config: {
        schema: StorageArchivistConfigSchema, namespace: STORAGE_NAME_SPACE, type: 'local',
      },
    })
    return notificationSettingsArchivist
  }
}

export const latestNotificationSettingsPayload = async (token: string): Promise<NotificationSettings | undefined> => {
  const archivist = await notificationSettingsStorageArchivist()
  return (await archivist.next({ order: 'desc', limit: 100 }))
    .find(payload => isNotificationSettings(payload) && payload.token === token) as NotificationSettings | undefined
}
