import type { StackProps } from '@mui/material'
import { Stack } from '@mui/material'
import { LinkEx } from '@xylabs/react-link'
import React from 'react'

interface SocialLinksDataProps {
  link: string
  socialName: string
}

const socialLinksData: SocialLinksDataProps[] = [
  {
    link: 'https://www.facebook.com/Dataism.org',
    socialName: 'Facebook',
  },
  {
    link: 'https://www.facebook.com/groups/dataists',
    socialName: 'Facebook Group',
  },
  {
    link: 'https://x.com/dataism_org',
    socialName: 'X',
  },
  {
    link: 'https://www.instagram.com/dataism_org',
    socialName: 'Instagram',
  },
]

export const SocialFooterLinks: React.FC<StackProps> = (props) => {
  return (
    <Stack gap={1} flexWrap="wrap" justifyContent="center" flexDirection="row" {...props}>
      {socialLinksData.map((social) => {
        return (
          <LinkEx key={social.socialName} href={social.link} target="_blank">
            {social.socialName}
          </LinkEx>
        )
      })}
    </Stack>
  )
}
