import { Provider as RollbarProvider } from '@rollbar/react'
import { Fbq, Gtag } from '@xylabs/react-pixel'
import React from 'react'
import type { Configuration } from 'rollbar'

import { getMetaPropertyContent } from '#lib'

import { AppInner } from './components/index.ts'

const rollbarConfig: Configuration | undefined = import.meta.env.VITE_ROLLBAR_TOKEN
  ? {
      accessToken: import.meta.env.VITE_ROLLBAR_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,
      environment: import.meta.env.VITE_ROLLBAR_ENVIRONMENT ?? 'development',
      payload: {
        client: {
          javascript: {
            source_map_enabled: true,
            guess_uncaught_frames: true,
            git_hash: getMetaPropertyContent('xy:git-hash'),
          },
        },
      },
    }
  : undefined

Fbq.init(import.meta.env.VITE_FACEBOOK_PIXEL_ID)
Gtag.init(import.meta.env.VITE_GOOGLE_GA4ID, import.meta.env.VITE_GOOGLE_AD_WORDS_ID, ['dataism.org'])

export const App: React.FC = () => {
  return rollbarConfig
    ? (
        <RollbarProvider config={rollbarConfig}>
          <AppInner />
        </RollbarProvider>
      )
    : <AppInner />
}
