import type { Payload } from '@xyo-network/payload-model'
import { isPayloadOfSchemaType, isPayloadOfSchemaTypeWithMeta } from '@xyo-network/payload-model'

import type { TaskRewardBase } from './Base.ts'

export const DatumTaskRewardSchema = 'org.dataism.journey.task.reward.datum' as const
export type DatumTaskRewardSchema = typeof DatumTaskRewardSchema

export interface DatumTaskRewardFields extends TaskRewardBase {
  amount: number
  description?: string
  rewardType: 'datum'
  title: string
}

export type DatumTaskReward = Payload<DatumTaskRewardFields, DatumTaskRewardSchema>
export const isDatumTaskRewardWithMeta = isPayloadOfSchemaTypeWithMeta<DatumTaskReward>(DatumTaskRewardSchema)
export const isDatumTaskReward = isPayloadOfSchemaType<DatumTaskReward>(DatumTaskRewardSchema)
