import { assertEx } from '@xylabs/sdk-js'
import React from 'react'
// eslint-disable-next-line import-x/no-internal-modules
import { createRoot } from 'react-dom/client'

import { App } from './app/index.ts'
import * as serviceWorker from './serviceWorker.ts'
const root = createRoot(assertEx(document.querySelector('#root')))

root.render(<App />)

serviceWorker.unregister()
