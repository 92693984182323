import {
  MenuItem, Typography, useTheme,
} from '@mui/material'
import { FlexCol } from '@xylabs/react-flexbox'
import { LinkEx } from '@xylabs/react-link'
import type { Dispatch, SetStateAction } from 'react'
import React, { useState } from 'react'

import { SlideInArrow } from '#components'

import { asNavBarHrefOrToProps, type NavBarItemProps } from '../NavData/index.ts'

export interface NavBarMenuItemProps {
  index: number
  item: NavBarItemProps
  navCategory: string
  openController: Dispatch<SetStateAction<[number, boolean]>>
  totalSectionItems: Array<NavBarItemProps>
}

export const NavBarMenuItem: React.FC<NavBarMenuItemProps> = ({
  openController,
  item, index, totalSectionItems, navCategory,
}) => {
  const [hovered, setHovered] = useState(false)
  const theme = useTheme()

  return (
    <MenuItem
      key={index}
      divider={index == totalSectionItems.length - 1 ? false : true}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      disabled={item.disabled}
      aria-disabled={!!item.disabled}
      sx={{
        '&:hover': { backgroundColor: 'transparent' },
        'alignItems': 'flex-start',
        'backgroundColor': 'transparent',
        'paddingY': '8px',
        'whiteSpace': 'normal',
      }}
      component={LinkEx}
      placement={`Nav Section ${navCategory} Item ${item}`}
      {...asNavBarHrefOrToProps({ to: item.to, href: item.href })}
      tabIndex={0}
      target={item.href ? '_blank' : '_self'}
      onClick={() => openController([index, false])}
    >
      <FlexCol alignItems="flex-start" textAlign="left">
        <Typography variant="button" color={hovered ? theme.palette.secondary.light : theme.palette.text.primary}>
          {item.linkText}
          {' '}
          <SlideInArrow inTrigger={hovered} />
        </Typography>
        <Typography variant="body2" color={hovered ? theme.palette.text.primary : theme.palette.text.secondary}>
          {item.desc}
        </Typography>
      </FlexCol>
    </MenuItem>
  )
}
