import type { Auth } from '@firebase/auth'
import { GoogleAuthProvider } from '@firebase/auth'

import { linkWithProvider, loginWithProvider } from '../lib/index.ts'

/**
 * Signs in a user with their Google account
 */
export const loginWithGoogle = (auth: Auth) => loginWithProvider(auth, new GoogleAuthProvider())

/**
 * Links a user with their Google account
 * @param auth The Firebase Auth instance
 */
export const linkWithGoogle = (auth: Auth) => linkWithProvider(auth, new GoogleAuthProvider())
