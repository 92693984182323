import type { Auth } from '@firebase/auth'
import { TwitterAuthProvider } from '@firebase/auth'

import { linkWithProvider, loginWithProvider } from '../lib/index.ts'

/**
 * Signs in a user with their Twitter account
 */
export const loginWithTwitter = (auth: Auth) => loginWithProvider(auth, new TwitterAuthProvider())

/**
 * Links a user with their Google account
 * @param auth The Firebase Auth instance
 */
export const linkWithTwitter = (auth: Auth) => linkWithProvider(auth, new TwitterAuthProvider())
