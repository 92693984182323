import { GenericHeroJourneyImageSlug } from '#img'
import {
  DataistPersonaJourneyData, LogoMemoryJourneyData, PillarsOfDataismJourneyData,
  TwoFactorAuthenticationBasicsJourneyData,
} from '#lib'
import { type Journey, JourneySchema } from '#models'

import { GenericJourneyData } from './WithJourney.ts'

export const generateLevel1Journeys = (): Journey[] => {
  const additionalJourneys: Journey[] = [
    {
      description: `Two Factor Authentication (2FA) is a security feature that helps protect your accounts from unauthorized access.
      Discover the details on how to set it up and why it is important.`,
      timeToComplete: (60 * 3) * 1000,
      heroImage: GenericHeroJourneyImageSlug,
      releaseDate: Infinity,
      schema: JourneySchema,
      slug: 'slug-2fa-journey',
      tasks: [],
      taskTypes: ['quiz'],
      title: 'Two Factor Authentication',
      topics: ['dataism', '2fa'],
    },
  ]

  const journeysToReturn: Journey[] = [
    DataistPersonaJourneyData,
    PillarsOfDataismJourneyData,
    TwoFactorAuthenticationBasicsJourneyData,
    LogoMemoryJourneyData,
    ...additionalJourneys,
  ]

  return journeysToReturn
}

export const generateLevel2Journeys = (): Journey[] => {
  let journeysToReturn: Journey[] = []
  for (let i = 7; i < 13; i++) {
    let newJourney: Journey = {
      ...GenericJourneyData,
      releaseDate: Infinity,
      description: 'This journey has not been release yet.  Check back later.',
      timeToComplete: (60 * 5) * 1000,
      heroImage: GenericHeroJourneyImageSlug,
      title: 'Coming Soon',
      slug: `slug-coming-soon-journey-${i}`,
    }
    newJourney.heroImage = `${GenericHeroJourneyImageSlug}-${i}`
    journeysToReturn.push(newJourney)
  }

  return journeysToReturn
}

export const generateLevel3Journeys = (): Journey[] => {
  let journeysToReturn: Journey[] = []
  for (let i = 1; i < 7; i++) {
    let newJourney: Journey = {
      ...GenericJourneyData,
      releaseDate: Infinity,
      description: 'This journey has not been release yet.  Check back later.',
      timeToComplete: (60 * 5) * 1000,
      heroImage: GenericHeroJourneyImageSlug,
      title: 'Coming Soon',
      slug: `slug-coming-soon-journey-${i}`,
    }
    newJourney.heroImage = `${GenericHeroJourneyImageSlug}-${i}`
    journeysToReturn.push(newJourney)
  }

  return journeysToReturn
}

export const generateJourneys = (level = 1): Journey[] => {
  switch (level) {
    case 1: {
      return generateLevel1Journeys()
    }
    case 2: {
      return generateLevel2Journeys()
    }
    case 3: {
      return generateLevel3Journeys()
    }
    default: {
      return generateLevel2Journeys()
    }
  }
}
