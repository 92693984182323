import {
  Stack,
  useMediaQuery, useTheme,
} from '@mui/material'
import type { LinkExProps } from '@xylabs/react-link'
import { LinkEx } from '@xylabs/react-link'
import React from 'react'

import { DataismIcon } from '#img'

export const Logo: React.FC<Omit<LinkExProps, 'href'>> = ({
  to = '/', toOptions, ...props
}) => {
  const theme = useTheme()
  const isNavbarMobile = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <LinkEx to={to} toOptions={toOptions} tabIndex={0} {...props}>
      <Stack display="flex">
        <DataismIcon style={{ height: isNavbarMobile ? 40 : 36 }} />
      </Stack>
    </LinkEx>
  )
}
