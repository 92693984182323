import type { Theme, ToolbarProps } from '@mui/material'
import { Toolbar, useMediaQuery } from '@mui/material'
import { ButtonEx } from '@xylabs/react-button'
import { DarkModeIconButtonForColorScheme } from '@xylabs/react-invertible-theme'
import type { ReactNode } from 'react'
import React from 'react'
import type { To } from 'react-router-dom'

import { useUser } from '#contexts'

import { NotificationsToggleIconButton } from './NotificationsButton.tsx'

export interface AppBarButtonsToolbarProps extends ToolbarProps {
  ctaLink?: To | string
  ctaText?: string
  customButton?: ReactNode
}

export const AppBarButtonsToolbar: React.FC<AppBarButtonsToolbarProps> = ({
  ctaText = 'Get Started', ctaLink = '/start?invite=0', sx, customButton, children, ...props
}) => {
  const isMedium = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'))
  const ctaLinkTypeTo: boolean = !ctaLink.toString().includes('//')
  const { user, loading } = useUser()

  return (
    <Toolbar sx={{ gap: 1, ...sx }} {...props}>
      {user
        ? null
        : loading
          ? null
          : <ButtonEx sx={{ display: isMedium ? 'none' : 'flex' }} variant="contained" to={ctaLink}>{ctaText}</ButtonEx>}
      {user
        ? <ButtonEx busy={loading} disabled={loading} variant="contained" to="/dashboard">Dashboard</ButtonEx>
        : <ButtonEx busy={loading} disabled={loading} variant="contained" to="/account">Login</ButtonEx>}
      {customButton
      ?? (ctaLinkTypeTo
        ? null
        : (
            null
          ))}
      {children}
      <DarkModeIconButtonForColorScheme size="small" />
      <NotificationsToggleIconButton size="small" />
    </Toolbar>
  )
}
