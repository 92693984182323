import {
  ArrowBack, ArrowForward, ArrowForwardRounded,
} from '@mui/icons-material'
import {
  Box,
  Card,
  CardContent,
  Container, IconButton,
  Stack, Typography,
  useTheme,
} from '@mui/material'
import { FlexCol, FlexRow } from '@xylabs/react-flexbox'
import { LinkEx } from '@xylabs/react-link'
import { useIsSmall } from '@xyo-network/react-shared'
import React, { useState } from 'react'

import { FullPageResponsiveBox } from '#components'
import { dataismPillarsData } from '#data'

export const Pillars: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const isSmall = useIsSmall()
  const theme = useTheme()

  const cardsPerPage = isSmall ? 1 : 3

  const cardWidthWithGap = isSmall ? 103 : 33

  const totalMoves = (dataismPillarsData.length + 1) - cardsPerPage

  const handlePrevious = () => {
    setCurrentIndex(prevIndex => Math.max(prevIndex - 1, 0))
  }

  const handleNext = () => {
    setCurrentIndex(prevIndex => Math.min(prevIndex + 1, totalMoves))
  }

  return (
    <FullPageResponsiveBox id="three-pillars">
      <Container
        sx={{
          display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: 2,
        }}
      >
        <Typography variant="body1" gutterBottom>Pillars of Dataism</Typography>
        <Stack flexDirection="column" alignItems="flex-start" gap={2}>
          <Box
            sx={{
              display: 'flex',
              overflow: 'hidden',
              width: '100%',
            }}
          >
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'row',
                transform: `translateX(-${currentIndex * cardWidthWithGap}%)`,
                transition: 'transform 0.5s ease-in-out',
                gap: theme.spacing(1),
                width: '100%',
              }}
            >
              {dataismPillarsData.map(pillar => (
                <Card
                  key={pillar.title}
                  sx={{
                    width: { xs: '100%', md: 'calc(33.33% - 16px)' }, // Full width on mobile, third on desktop with gap
                    flexShrink: 0, // Prevent shrinking
                  }}
                >
                  <CardContent sx={{ height: { md: '100%' } }}>
                    <FlexCol sx={{
                      alignItems: 'flex-start', justifyContent: 'space-between', height: { md: '100%' },
                    }}
                    >
                      <FlexCol sx={{ alignItems: 'flex-start' }}>
                        <Box height="100px"></Box>
                        <Typography variant="h5" fontWeight="bold">{pillar.title}</Typography>
                        <Typography variant="body1">
                          {pillar.shortDesc}
                        </Typography>
                      </FlexCol>
                    </FlexCol>
                  </CardContent>
                </Card>
              ))}
              <Card
                variant="outlined"
                elevation={0}
                sx={{
                  'width': { xs: '100%', md: 'calc(33.33% - 16px)' },
                  'flexShrink': 0,
                  'backgroundColor': 'transparent',
                  '&:hover': { textDecoration: 'underline', textUnderlineOffset: '5px' },
                }}
                component={LinkEx}
                to="/pillars"
              >
                <CardContent sx={{ height: { md: '100%' } }}>
                  <FlexCol sx={{
                    alignItems: 'center', justifyContent: 'center', height: { md: '100%' },
                  }}
                  >
                    <FlexRow gap={1} alignItems="center" justifyContent="center">
                      <Typography variant="button">Learn More</Typography>
                      <ArrowForwardRounded fontSize="small" />
                    </FlexRow>
                  </FlexCol>
                </CardContent>
              </Card>
            </Stack>
          </Box>
        </Stack>
        <Stack sx={{
          display: 'flex',
          justifyContent: { xs: 'center', md: 'flex-end' },
          marginTop: 2,
          gap: 2,
          flexDirection: 'row',
        }}
        >
          <IconButton onClick={handlePrevious} disabled={currentIndex === 0}>
            <ArrowBack />
          </IconButton>
          <IconButton
            onClick={handleNext}
            disabled={currentIndex >= totalMoves}
          >
            <ArrowForward />
          </IconButton>
        </Stack>
      </Container>
    </FullPageResponsiveBox>
  )
}
