import React, { lazy, Suspense } from 'react'

import type { PersonaRadarChartProps } from './PersonaScoreChart.tsx'

const LazyPersonaScoreChart = lazy(() => import('./PersonaScoreChart.tsx'))
export const PersonaScoreChart: React.FC<PersonaRadarChartProps> = (props) => {
  return (
    <Suspense fallback={<div />}>
      <LazyPersonaScoreChart {...props} />
    </Suspense>
  )
}
