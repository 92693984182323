import { AreYouADataistJourneyImageSlug } from '#img'
import type { Journey, Slug } from '#models'

import { AreYouAdDataistTaskData } from './WithTask.ts'

export const AreYouADataistJourneySlug: Slug = 'slug-are-you-a-dataist-journey'

export const AreYouADataistJourneyData: Journey = {
  // eslint-disable-next-line @stylistic/max-len
  description: "Begin your Dataism Journey — find out if you're a Dataist! Some people may call themselves a tech-lover, and others may say that they're tech-cursed, but no matter your experience or love of technology, you're probably a Dataist!",
  timeToComplete: 5000,
  heroImage: AreYouADataistJourneyImageSlug,
  schema: 'org.dataism.journey',
  slug: AreYouADataistJourneySlug,
  tasks: [AreYouAdDataistTaskData],
  taskTypes: ['quiz'],
  title: 'Are You A Dataist?',
  topics: ['dataism'],
}
