import { TwoFactorAuthJourneyImageSlug } from '#img'
import {
  type Journey, JourneySchema, type Slug,
} from '#models'

import { TwoFactorAuthenticationBasicsQuizTaskData, TwoFactorAuthenticationBasicsReadingTaskData } from './WithTask.ts'

export const TwoFactorAuthenticationBasicsJourneySlug: Slug = 'slug-two-factor-authentication-journey'

export const TwoFactorAuthenticationBasicsJourneyData: Journey = {
  description: 'Learn about 2-Factor Authentication with Google Authenticator',
  timeToComplete: 60 * 5000,
  heroImage: TwoFactorAuthJourneyImageSlug,
  schema: JourneySchema,
  slug: TwoFactorAuthenticationBasicsJourneySlug,
  tasks: [TwoFactorAuthenticationBasicsReadingTaskData, TwoFactorAuthenticationBasicsQuizTaskData],
  taskTypes: ['quiz'],
  title: '2-Factor Authentication Basics',
  topics: ['2-factor-authentication', 'google-authenticator'],
}
