import React, { lazy, Suspense } from 'react'

const EmptyPersonaScoreChartLazy = lazy(() => import('./EmptyPersonaScoreChart.tsx'))
export const EmptyPersonaScoreChart: React.FC = () => {
  return (
    <Suspense fallback={<div />}>
      <EmptyPersonaScoreChartLazy />
    </Suspense>
  )
}
