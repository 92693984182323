import { Person } from '@mui/icons-material'
import {
  Card,
  CardActions, CardContent, CardHeader, type CardProps,
  List, ListItem,
} from '@mui/material'
import { ButtonEx } from '@xylabs/react-button'
import { useColorSchemeEx } from '@xylabs/react-invertible-theme'
import { DateTime } from 'luxon'
import React, { forwardRef } from 'react'

import { useAuth, useUser } from '#contexts'
import {
  linkWithFacebook, linkWithGithub, linkWithGoogle, linkWithTwitter, logout,
} from '#users'

import {
  GithubIconBlack, GithubIconWhite, GoogleIcon, MetaIcon, XIconBlack, XIconWhite,
} from './icons/index.ts'

// TODO: Temporarily hide account merge until we are ready to implement it
// to ensure people can't use compromised accounts to take over other accounts
// or unverified emails and merge with (different) verified socials
const allowAccountMerge = false

export interface UserCardProps extends CardProps {}

export const UserCard = forwardRef<HTMLDivElement, UserCardProps>((props, ref) => {
  const { user } = useUser()
  const auth = useAuth()
  const { darkMode } = useColorSchemeEx()

  const creationTime = (user && user.metadata.creationTime)
  const lastSignInTime = (user && user.metadata.lastSignInTime)

  const dateCreated = creationTime ? DateTime.fromHTTP(creationTime) : null
  const lastLogged = lastSignInTime ? DateTime.fromHTTP(lastSignInTime) : null

  return (
    <Card ref={ref} {...props}>
      <CardHeader title={user?.email} avatar={<Person />} />
      <CardContent>
        <List>
          <ListItem>
            Member Since:
            {' '}
            {dateCreated?.toLocaleString()}
          </ListItem>
          <ListItem>
            Last Login:
            {' '}
            {lastLogged?.toLocaleString()}
          </ListItem>
        </List>
        <CardActions>
          <ButtonEx fullWidth variant="outlined" onClick={() => logout(auth)}>
            Log Out
          </ButtonEx>
          {allowAccountMerge && (
            <>
              <ButtonEx
                startIcon={(<img src={GoogleIcon} height={24} width={24} />)}
                variant="outlined"
                fullWidth
                onClick={() => linkWithGoogle(auth)}
              >
                Connect Google Account
              </ButtonEx>
              <ButtonEx
                startIcon={(<img src={MetaIcon} height={24} width={24} />)}
                variant="outlined"
                fullWidth
                onClick={() => linkWithFacebook(auth)}
              >
                Connect Facebook Account
              </ButtonEx>
              <ButtonEx
                startIcon={(<img src={darkMode ? XIconWhite : XIconBlack} height={24} width={24} />)}
                variant="outlined"
                fullWidth
                onClick={() => linkWithTwitter(auth)}
              >
                Connect X Account
              </ButtonEx>
              <ButtonEx
                startIcon={(<img src={darkMode ? GithubIconWhite : GithubIconBlack} height={24} width={24} />)}
                variant="outlined"
                fullWidth
                onClick={() => linkWithGithub(auth)}
              >
                Connect Github Account
              </ButtonEx>
            </>
          )}
        </CardActions>
      </CardContent>
    </Card>
  )
})

UserCard.displayName = 'UserCard'
