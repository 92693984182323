import { connectAuthEmulator, getAuth } from '@firebase/auth'

import { app } from './app.ts'

const auth = getAuth(app)
// export const auth = initializeAuth(app, {
//   persistence: browserLocalPersistence,
//   popupRedirectResolver: undefined,
// })

// If we're running on localhost, connect to the Auth emulator
if (import.meta.env.VITE_HOST_URL?.includes('localhost')) {
  connectAuthEmulator(auth, 'http://127.0.0.1:9099', { disableWarnings: true })
}
export { auth }
