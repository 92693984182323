import { PillarsOfDataismJourneyImageSlug } from '#img'
import type { Journey, Slug } from '#models'

import { PillarsOfDataismQuizTaskData, PillarsOfDataismReadingTaskData } from './WithTask.ts'

export const PillarsOfDataismJourneySlug: Slug = 'slug-pillars-of-dataism-journey'

export const PillarsOfDataismJourneyData: Journey = {

  description: 'Learn about some of the central tenants of Dataism.',
  timeToComplete: 60 * 5000,
  releaseDate: new Date('Fri Oct 25 2024 05:00:00 GMT+0000').getTime(),
  heroImage: PillarsOfDataismJourneyImageSlug,
  schema: 'org.dataism.journey',
  slug: PillarsOfDataismJourneySlug,
  tasks: [PillarsOfDataismReadingTaskData, PillarsOfDataismQuizTaskData],
  taskTypes: ['quiz'],
  title: 'The Pillars of Dataism',
  topics: ['dataism'],
}
