import React from 'react'

import { DataismBasicHero, type DataismBasicHeroProps } from '#components'

export type Optional<T extends object, F extends keyof T> = Omit<T, F> & Partial<Pick<T, F>>

export type DeleteDataHeroProps = Optional<DataismBasicHeroProps, 'desc' | 'title'>

export const DeleteDataHero: React.FC<DeleteDataHeroProps> = ({
  title = 'Request Data Deletion',
  desc = 'To request a deletion of your data, please email community@dataism.org with your request.',
  ...props
}) => {
  return (
    <DataismBasicHero
      title={title}
      desc={desc}
      overflow="hidden"
      {...props}
    />
  )
}
