import { type JourneyTask, JourneyTaskSchema } from '#models'

import { OneHundredDatum } from '../shared/index.ts'
import { DataistPersonaQuizBadge } from './Badge.ts'
import { DataistPersonaQuiz } from './Data.tsx'
import { DataistPersonaQuizTaskSlug } from './Slugs.ts'
import { DataistPersonaQuizValidation } from './Validation.ts'

export const DataistPersonaQuizTaskData: JourneyTask = {
  description: 'Discover what type of Dataist you are by taking this quiz.',
  metadata: ['quiz'],
  resource: [],
  schema: JourneyTaskSchema,
  slug: DataistPersonaQuizTaskSlug,
  timeToComplete: (60 * 2) * 1000,
  title: 'Dataist Persona Quiz',
  taskSet: {
    reward: [DataistPersonaQuizBadge, OneHundredDatum],
    validatedRequirements: [{ requirement: DataistPersonaQuiz, validation: DataistPersonaQuizValidation }],
  },
}
