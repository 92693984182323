import { useTheme } from '@mui/material'
import type { ButtonExProps } from '@xylabs/react-button'
import { ButtonEx } from '@xylabs/react-button'
import React from 'react'

export type ButtonSectionProps = ButtonExProps & {
  buttonText?: string
}

export const ButtonSection: React.FC<ButtonSectionProps> = ({
  href, to, toOptions, buttonText, ...props
}) => {
  const theme = useTheme()
  return href
    ? (
        <ButtonEx
          marginTop={1}
          marginBottom={1}
          target="_blank"
          href={href}
          variant="contained"
          paddingX={3}
          sx={{
            display: href || to ? 'flex' : 'none',
            marginLeft: { sm: theme.spacing(0), xs: theme.spacing(2) },
            marginRight: { sm: theme.spacing(1), xs: theme.spacing(2) },
          }}
          {...props}
        >
          {buttonText}
        </ButtonEx>
      )
    : to
      ? (
          <ButtonEx
            marginTop={1}
            marginBottom={1}
            to={to}
            toOptions={toOptions}
            variant="contained"
            paddingX={3}
            sx={{
              display: href || to ? 'flex' : 'none',
              marginLeft: { sm: theme.spacing(0), xs: theme.spacing(2) },
              marginRight: { sm: theme.spacing(1), xs: theme.spacing(2) },
            }}
            {...props}
          >
            {buttonText}
          </ButtonEx>
        )
      : (
          <ButtonEx
            marginTop={1}
            marginBottom={1}
            variant="contained"
            paddingX={3}
            sx={{
              display: href || to ? 'flex' : 'none',
              marginLeft: { sm: theme.spacing(0), xs: theme.spacing(2) },
              marginRight: { sm: theme.spacing(1), xs: theme.spacing(2) },
            }}
            {...props}
          >
            {buttonText}
          </ButtonEx>
        )
}
