import {
  Stack, Typography, useTheme,
} from '@mui/material'
import { RotationAnimation } from '@xylabs/react-animation'
import { FlexGrowCol, FlexRow } from '@xylabs/react-flexbox'
import { LinkEx } from '@xylabs/react-link'
import type { FooterProps } from '@xyo-network/react-footer'
import { Footer } from '@xyo-network/react-footer'
import React from 'react'

import { DataismIcon } from '#img'

import { CompanyFooterLinks } from './CompanyFooterLinks.tsx'
import { SocialFooterLinks } from './SocialFooterLinks.tsx'

export const DataismFooter: React.FC<FooterProps> = (props) => {
  const theme = useTheme()
  return (
    <Footer
      {...props}
    >
      <FlexGrowCol sx={{
        backgroundColor: theme.palette.background.paper,
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingY: 2,
        gap: 2,
      }}
      >
        <FlexRow justifyContent="flex-start">
          <LinkEx to="/">
            <Stack direction="row" paddingRight={1} spacing={0.5} alignItems="center" justifyContent="center">
              <RotationAnimation rotation={20}>
                <DataismIcon fontSize="large" />
              </RotationAnimation>
            </Stack>
          </LinkEx>
        </FlexRow>
        <Typography>
          ©
          &nbsp;
          {new Date().getFullYear()}
          &nbsp;
          Dataism.org
        </Typography>
        <SocialFooterLinks />
        <CompanyFooterLinks />
      </FlexGrowCol>
    </Footer>
  )
}
