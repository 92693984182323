import { LogoMemoryJourneyImageSlug } from '#img'
import type { Journey, Slug } from '#models'

import { TaskData } from './WithTask.ts'

export const LogoMemoryJourneySlug: Slug = 'slug-logo-memory-level-1-journey'

export const LogoMemoryJourneyData: Journey = {

  description: "Test your logo knowledge — how well do you know the internet's biggest companies?",
  timeToComplete: 5000,
  heroImage: LogoMemoryJourneyImageSlug,
  schema: 'org.dataism.journey',
  slug: LogoMemoryJourneySlug,
  releaseDate: Infinity,
  tasks: [TaskData],
  taskTypes: ['quiz'],
  title: 'Logo Memory: Level 1',
  topics: ['dataism'],
}
