import { useUserEvents } from '@xylabs/react-pixel'
import type { OverridedMixpanel } from 'mixpanel-browser'

import { DataismUserEvents } from './UserEvents.ts'

declare global {
  var mixpanel: OverridedMixpanel
}

// we call this outside the function to force initialization on page load
let instance: DataismUserEvents

export const getDataismUserEvents = (mixpanelToken?: string) => {
  console.log('getDataismUserEvents', !!mixpanelToken)
  return instance = instance ?? DataismUserEvents.get(mixpanelToken
    ? (() => {
        mixpanel.init(mixpanelToken)
        return mixpanel
      })()
    : undefined)
}

export const useDataismUserEvents = () => {
  return useUserEvents(true) as DataismUserEvents
}

export * from './DataismEventHandler.ts'
export * from './DataismEventHandlerInterface.ts'
export { DataismUserEvents } from './UserEvents.ts'
