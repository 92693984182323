import type { DefaultColorScheme } from '@mui/material/styles/createThemeWithVars.js'
import { InvertibleMuiThemeProvider } from '@xylabs/react-invertible-theme'
import React, { useMemo } from 'react'

import { AppBody } from '../Body.tsx'
import { DataismTheme } from './Theme.ts'

export const AppThemeBody: React.FC = () => {
  const initialValue: DefaultColorScheme | 'system' = useMemo(() => {
    const validValues: DefaultColorScheme[] = ['light', 'dark']
    const savedValue = localStorage.getItem('mui-mode')
    if (savedValue && validValues.includes(savedValue as DefaultColorScheme)) return savedValue as DefaultColorScheme
    return 'system'
  }, [])
  return (
    <InvertibleMuiThemeProvider
      defaultMode={initialValue}
      theme={DataismTheme}
    >
      <AppBody />
    </InvertibleMuiThemeProvider>
  )
}
