import { type QuizRequirement } from '#models'
import { QuizRequirementSchema } from '#models'

import { PillarsOfDataismQuizDataSlug, PillarsOfDataismQuizTaskSlug } from './Slugs.ts'

export const PillarsOfDataismQuizData: QuizRequirement = {
  // eslint-disable-next-line @stylistic/max-len
  description: "Begin your Dataism Journey — find out if you're a Dataist! Some people may call themselves a tech-lover, and others may say that they're tech-cursed, but no matter your experience or love of technology, you're probably a Dataist!",
  slug: PillarsOfDataismQuizDataSlug,
  task: PillarsOfDataismQuizTaskSlug,
  type: 'quiz',
  schema: QuizRequirementSchema,
  questions: [
    {
      type: 'multiple-choice',
      questionText: 'What is the fundamental belief of Dataism?',
      options: [
        {
          name: 'Data is the most valuable and universal resource.',
          slug: 'slug-data-resource',
          value: { correct: 1, answered: 1 },
        },
        {
          name: 'Humans are the most valuable resource in decision-making.',
          slug: 'slug-human-resource',
          value: { incorrect: 1, answered: 1 },
        },
        {
          name: 'Data has value only in technological systems.',
          slug: 'slug-technological-value',
          value: { incorrect: 1, answered: 1 },
        },
        {
          name: 'Data is secondary to human emotion in decision-making.',
          slug: 'slug-emotion',
          value: { incorrect: 1, answered: 1 },
        },
      ],
    },
    {
      type: 'true-false',
      questionText: 'Dataism holds that reality is best understood through the continuous generation and processing of data.',
      options: [
        {
          slug: 'slug-true',
          value: { correct: 1, answered: 1 },
          name: 'True',
        },
        {
          slug: 'slug-false',
          value: { incorrect: 1, answered: 1 },
          name: 'False',
        },
      ],
    },
    {
      type: 'multiple-choice',
      questionText: 'What role do algorithms play in Dataism?',
      options: [
        {
          name: 'They are more efficient and impartial than human decision-makers.',
          slug: 'slug-algorithms-efficient',
          value: { correct: 1, answered: 1 },
        },
        {
          name: 'They are used primarily for entertainment and non-essential tasks.',
          slug: 'slug-algorithms-entertainment',
          value: { incorrect: 1, answered: 1 },
        },
        {
          name: 'They are trusted less than human decision-makers.',
          slug: 'slug-human-decision',
          value: { incorrect: 1, answered: 1 },
        },
        {
          name: 'Algorithms are viewed as unreliable in data processing.',
          slug: 'slug-algorithms-unreliable',
          value: { incorrect: 1, answered: 1 },
        },
      ],
    },
    {
      type: 'true-false',
      questionText: 'Dataism values collective intelligence over individual wisdom.',
      options: [
        {
          slug: 'slug-true',
          value: { correct: 1, answered: 1 },
          name: 'True',
        },
        {
          slug: 'slug-false',
          value: { incorrect: 1, answered: 1 },
          name: 'False',
        },
      ],
    },
    {
      type: 'multiple-choice',
      questionText: 'What is the guiding principle of Dataism ethics?',
      options: [
        {
          name: 'Moral decisions are guided by empirical data and the greater good.',
          slug: 'slug-data-driven-ethics',
          value: { correct: 1, answered: 1 },
        },
        {
          name: 'Moral decisions are primarily guided by emotion.',
          slug: 'slug-emotion-driven-ethics',
          value: { incorrect: 1, answered: 1 },
        },
        {
          name: 'Decisions are based on individual intuition, not data.',
          slug: 'slug-intuition',
          value: { incorrect: 1, answered: 1 },
        },
        {
          name: 'Moral decisions are irrelevant in Dataism.',
          slug: 'slug-irrelevant-ethics',
          value: { incorrect: 1, answered: 1 },
        },
      ],
    },
    {
      type: 'multiple-choice',
      questionText: 'How does Dataism view continuous optimization?',
      options: [
        {
          name: 'As a key aspect of improving systems through data analysis.',
          slug: 'slug-continuous-optimization',
          value: { correct: 1, answered: 1 },
        },
        {
          name: 'As a temporary solution to fix immediate problems.',
          slug: 'slug-temporary-solution',
          value: { incorrect: 1, answered: 1 },
        },
        {
          name: 'As irrelevant to long-term success.',
          slug: 'slug-no-optimization',
          value: { incorrect: 1, answered: 1 },
        },
        {
          name: 'As something unnecessary for most systems.',
          slug: 'slug-unnecessary-optimization',
          value: { incorrect: 1, answered: 1 },
        },
      ],
    },
    {
      type: 'true-false',
      questionText: 'Dataism emphasizes openness and transparency in data collection and use.',
      options: [
        {
          slug: 'slug-true',
          value: { correct: 1, answered: 1 },
          name: 'True',
        },
        {
          slug: 'slug-false',
          value: { incorrect: 1, answered: 1 },
          name: 'False',
        },
      ],
    },
    {
      type: 'multiple-choice',
      questionText: 'How does Dataism view the role of non-human intelligence?',
      options: [
        {
          name: 'Non-human intelligence is trusted to process vast amounts of data impartially.',
          slug: 'slug-non-human-trust',
          value: { correct: 1, answered: 1 },
        },
        {
          name: 'Human intelligence is always superior to machine learning.',
          slug: 'slug-human-superior',
          value: { incorrect: 1, answered: 1 },
        },
        {
          name: 'Non-human intelligence is only useful for low-priority tasks.',
          slug: 'slug-low-priority-tasks',
          value: { incorrect: 1, answered: 1 },
        },
        {
          name: 'Dataism doesn’t trust non-human intelligence for data analysis.',
          slug: 'slug-no-trust',
          value: { incorrect: 1, answered: 1 },
        },
      ],
    },
    {
      type: 'multiple-choice',
      questionText: 'What does the concept of interconnectedness in Dataism imply?',
      options: [
        {
          name: 'All systems are part of an interconnected network of data.',
          slug: 'slug-interconnectedness',
          value: { correct: 1, answered: 1 },
        },
        {
          name: 'Systems operate in isolation with minimal data sharing.',
          slug: 'slug-isolation',
          value: { incorrect: 1, answered: 1 },
        },
        {
          name: 'Only biological systems are interconnected through data.',
          slug: 'slug-bio-interconnected',
          value: { incorrect: 1, answered: 1 },
        },
        {
          name: 'Technological systems do not rely on interconnected data.',
          slug: 'slug-tech-isolation',
          value: { incorrect: 1, answered: 1 },
        },
      ],
    },
  ],
}
