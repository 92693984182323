import React from 'react'

import { DataismBasePage } from '#chrome'

import { MissionHero } from './Hero.tsx'

export const MissionPage: React.FC = () => {
  return (
    <DataismBasePage title="Mission">
      <MissionHero />
    </DataismBasePage>
  )
}
