import {
  Box,
  styled,
} from '@mui/material'

export const StyledBox = styled(Box)(({ theme }) => ({
  a: {
    color: theme.palette.secondary.main,
    target: '_blank',
  },
  blockquote: {
    ...theme.typography.h5,
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    paddingLeft: theme.spacing(1),
    marginLeft: 0,
    marginRight: 0,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    opacity: 0.8,
  },
  figure: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  figcaption: {
    fontStyle: 'italic',
    textAlign: 'center',
    ...theme.typography.subtitle2,
  },
  h1: {
    ...theme.typography.h1,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: 0,
    paddingTop: theme.spacing(1),

  },
  h2: {
    ...theme.typography.h2,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: 0,
    paddingTop: theme.spacing(1),

  },
  h3: {
    ...theme.typography.h3,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: 0,
    paddingTop: theme.spacing(1),

  },
  h4: {
    ...theme.typography.h5,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: 0,
    paddingTop: theme.spacing(1),

  },
  hr: {
    opacity: 0.15,
    width: 0.7,
  },
  img: {
    borderRadius: theme.shape.borderRadius,
    height: 'auto',
    maxWidth: '100%',
  },
  p: {
    ...theme.typography.body1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))
