import type { NavigateOptions } from 'react-router-dom'

export interface NavbarSectionProps {
  name: string
  sectionItems?: Array<NavBarItemProps>
  to: string | undefined
}

export interface BaseNavBarItemProps {
  desc?: string
  disabled?: boolean
  linkText: string
}

export interface NavBarItemHrefProps {
  href: string
  to?: never
  toOptions?: never
}

export interface NavBarItemToProps {
  href?: never
  to: string
  toOptions?: NavigateOptions
}

export interface NavBarItemNoNavProps {
  href?: never
  to?: never
  toOptions?: never
}

export type NavBarHrefOrToProps = NavBarItemHrefProps | NavBarItemToProps | NavBarItemNoNavProps
export type NavBarHrefAndToProps = Partial<Pick<NavBarItemHrefProps, 'href'>> & Partial<Pick<NavBarItemToProps, 'to' | 'toOptions'>>

export const asNavBarHrefOrToProps = (props: NavBarHrefAndToProps): NavBarHrefOrToProps => {
  return props.href ? { href: props.href } : props.to ? { to: props.to, toOptions: props.toOptions } : {}
}

export type NavBarItemProps = BaseNavBarItemProps & NavBarHrefOrToProps
