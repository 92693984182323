import { useContext } from 'react'

import type { UserContextType } from './Context.ts'
import { UserContext } from './Context.ts'

// Custom hook to use the UserContext
export const useUser = (): UserContextType => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}
