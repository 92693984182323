import type { BusyBoxProps } from '@xylabs/react-flexbox'
import { FlexCol } from '@xylabs/react-flexbox'
import React from 'react'

import { DataismBasicHero } from '#components'

export const PillarsHero: React.FC<BusyBoxProps> = ({ ...props }) => {
  return (
    <FlexCol
      alignItems="stretch"
      sx={{
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
      {...props}
    >
      <DataismBasicHero
        title="The Pillars of Dataism"
        desc="Understand the concepts behind Dataism"
      />
    </FlexCol>
  )
}
