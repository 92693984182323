import { Badge, styled } from '@mui/material'

export const StyledFabBadge = styled(Badge, { name: 'StyledBadge' })(() => ({
  '& .MuiBadge-badge': {
    position: 'fixed',
    right: '23px',
    bottom: '46px',
    zIndex: 1052,
    top: 'auto',
  },
}))
