import { type QuizRequirement, QuizRequirementSchema } from '#models'

import { DataistPersonaQuizSlug, DataistPersonaQuizTaskSlug } from './Slugs.ts'

export const DataistPersonaQuiz: QuizRequirement = {
  title: 'What kind of Dataist are you?',
  slug: DataistPersonaQuizSlug,
  task: DataistPersonaQuizTaskSlug,
  type: 'quiz',
  schema: QuizRequirementSchema,
  questions: [
    {
      type: 'multiple-choice',
      questionText: 'How do you feel about using new technology?',
      options: [
        {
          name: 'I love trying out new tech.',
          slug: 'slug-love',
          value: {
            answered: 1, vex: 3, nova: 1, rook: 0, aegis: 0, lux: 2, echo: 1, myca: 1, cass: 0,
          },
        },
        {
          name: "I'm interested, but I hesitate before trying.",
          slug: 'slug-interested',
          value: {
            answered: 1, nova: 3, vex: 1, rook: 1, aegis: 1, lux: 1, echo: 0, myca: 1, cass: 1,
          },
        },
        {
          name: 'I prefer to stick with what I know.',
          slug: 'slug-stick-to-known',
          value: {
            answered: 1, rook: 3, aegis: 2, lux: 0, vex: 0, nova: 1, echo: 1, myca: 1, cass: 3,
          },
        },
        {
          name: 'I try it only if it benefits the team.',
          slug: 'slug-only-if-benefits',
          value: {
            answered: 1, myca: 3, lux: 1, vex: 0, rook: 1, echo: 1, nova: 0, aegis: 0, cass: 0,
          },
        },
        {
          name: 'I rarely try new tech unless necessary.',
          slug: 'slug-rarely',
          value: {
            answered: 1, cass: 3, aegis: 2, rook: 1, vex: 0, echo: 0, lux: 0, nova: 0, myca: 0,
          },
        },
      ],
    },
    {
      type: 'multiple-choice',
      questionText: 'How do you approach learning new skills?',
      options: [
        {
          name: 'I love exploring and trying new things.',
          slug: 'slug-exploring',
          value: {
            answered: 1, nova: 3, vex: 2, echo: 1, lux: 1, rook: 0, aegis: 0, myca: 0, cass: 0,
          },
        },
        {
          name: 'I stick to proven methods that work.',
          slug: 'slug-proven-methods',
          value: {
            answered: 1, cass: 3, rook: 2, aegis: 2, vex: 0, nova: 0, echo: 0, lux: 0, myca: 0,
          },
        },
        {
          name: 'I enjoy learning in collaboration with others.',
          slug: 'slug-collaboration',
          value: {
            answered: 1, myca: 3, nova: 1, lux: 1, rook: 0, echo: 0, aegis: 0, vex: 0, cass: 0,
          },
        },
        {
          name: 'I focus on how the skills can help long-term.',
          slug: 'slug-long-term',
          value: {
            answered: 1, lux: 3, nova: 1, echo: 0, rook: 0, aegis: 0, vex: 0, myca: 0, cass: 0,
          },
        },
        {
          name: 'I am hesitant to learn anything new unless needed.',
          slug: 'slug-hesitant',
          value: {
            answered: 1, cass: 2, aegis: 2, rook: 1, nova: 0, vex: 0, echo: 0, lux: 0, myca: 0,
          },
        },
      ],
    },
    {
      type: 'multiple-choice',
      questionText: 'How do you organize tasks?',
      options: [
        {
          name: 'I love making to-do lists.',
          slug: 'slug-to-do-lists',
          value: {
            answered: 1, rook: 3, aegis: 1, lux: 1, vex: 0, nova: 1, echo: 0, myca: 0, cass: 1,
          },
        },
        {
          name: 'I jump between tasks based on what’s urgent.',
          slug: 'slug-urgent',
          value: {
            answered: 1, vex: 3, nova: 2, echo: 1, lux: 0, rook: 0, aegis: 0, myca: 0, cass: 0,
          },
        },
        {
          name: 'I try to prioritize but find it hard sometimes.',
          slug: 'slug-prioritize',
          value: {
            answered: 1, lux: 2, rook: 2, nova: 1, echo: 1, aegis: 0, myca: 0, vex: 0, cass: 1,
          },
        },
        {
          name: 'I rely on team collaboration to stay organized.',
          slug: 'slug-team-collaboration',
          value: {
            answered: 1, myca: 3, vex: 1, lux: 0, rook: 0, nova: 0, echo: 0, aegis: 1, cass: 0,
          },
        },
        {
          name: 'I focus on maintaining steady progress.',
          slug: 'slug-steady-progress',
          value: {
            answered: 1, cass: 3, rook: 1, aegis: 2, nova: 0, vex: 0, echo: 0, lux: 0, myca: 0,
          },
        },
      ],
    },
    {
      type: 'multiple-choice',
      questionText: 'How do you handle mistakes?',
      options: [
        {
          name: 'I see mistakes as part of the learning process.',
          slug: 'slug-learning-process',
          value: {
            answered: 1, nova: 3, vex: 1, lux: 1, echo: 2, rook: 0, aegis: 0, myca: 0, cass: 0,
          },
        },
        {
          name: 'I avoid mistakes at all costs.',
          slug: 'slug-avoid-mistakes',
          value: {
            answered: 1, aegis: 3, rook: 2, myca: 1, lux: 1, vex: 0, echo: 0, nova: 0, cass: 2,
          },
        },
        {
          name: 'I feel frustrated and try to fix it immediately.',
          slug: 'slug-fix-immediately',
          value: {
            answered: 1, echo: 3, vex: 2, nova: 1, rook: 0, aegis: 0, lux: 0, myca: 0, cass: 0,
          },
        },
        {
          name: 'I prefer to step back and reassess.',
          slug: 'slug-reassess',
          value: {
            answered: 1, rook: 2, lux: 2, nova: 1, aegis: 1, myca: 0, vex: 0, echo: 0, cass: 1,
          },
        },
        {
          name: 'I learn best by reflecting with others.',
          slug: 'slug-reflecting',
          value: {
            answered: 1, myca: 3, nova: 1, echo: 0, lux: 0, rook: 0, aegis: 0, vex: 0, cass: 0,
          },
        },
      ],
    },
    {
      type: 'multiple-choice',
      questionText: 'How do you approach decision-making?',
      options: [
        {
          name: 'I go with my gut instinct.',
          slug: 'slug-gut-instinct',
          value: {
            answered: 1, vex: 3, nova: 2, echo: 1, lux: 0, rook: 0, aegis: 0, myca: 0, cass: 0,
          },
        },
        {
          name: 'I rely on data to make decisions.',
          slug: 'slug-rely-on-data',
          value: {
            answered: 1, rook: 3, lux: 1, aegis: 1, echo: 0, nova: 1, vex: 0, myca: 0, cass: 2,
          },
        },
        {
          name: 'I gather feedback from others first.',
          slug: 'slug-gather-feedback',
          value: {
            answered: 1, myca: 3, lux: 1, rook: 0, vex: 0, nova: 0, echo: 0, aegis: 0, cass: 0,
          },
        },
        {
          name: 'I think about the long-term impact.',
          slug: 'slug-long-term-impact',
          value: {
            answered: 1, lux: 3, rook: 1, aegis: 1, nova: 0, vex: 0, echo: 0, myca: 0, cass: 1,
          },
        },
        {
          name: 'I evaluate slowly and methodically.',
          slug: 'slug-methodical',
          value: {
            answered: 1, cass: 3, aegis: 1, rook: 1, lux: 0, vex: 0, nova: 0, echo: 0, myca: 0,
          },
        },
      ],
    },
    {
      type: 'multiple-choice',
      questionText: 'How do you handle change?',
      options: [
        {
          name: 'I embrace change and see it as an opportunity.',
          slug: 'slug-embrace',
          value: {
            answered: 1, vex: 3, nova: 1, lux: 2, echo: 2, rook: 0, aegis: 0, myca: 0, cass: 0,
          },
        },
        {
          name: 'I cautiously evaluate whether change is beneficial.',
          slug: 'slug-evaluate',
          value: {
            answered: 1, cass: 3, aegis: 2, rook: 1, lux: 1, vex: 0, nova: 0, echo: 0, myca: 0,
          },
        },
        {
          name: 'I trust change only if it directly benefits my team.',
          slug: 'slug-benefits-team',
          value: {
            answered: 1, myca: 3, lux: 1, rook: 0, aegis: 0, vex: 0, nova: 0, echo: 0, cass: 1,
          },
        },
        {
          name: "I don't welcome change and prefer stability.",
          slug: 'slug-stability',
          value: {
            answered: 1, cass: 2, rook: 2, aegis: 1, nova: 0, vex: 0, lux: 0, echo: 0, myca: 0,
          },
        },
        {
          name: 'I evaluate change for long-term outcomes.',
          slug: 'slug-long-term-outcomes',
          value: {
            answered: 1, lux: 3, rook: 1, nova: 1, aegis: 1, vex: 0, echo: 0, myca: 0, cass: 0,
          },
        },
      ],
    },
    {
      type: 'multiple-choice',
      questionText: 'How do you respond to feedback?',
      options: [
        {
          name: 'I appreciate and implement changes immediately.',
          slug: 'slug-implement-changes',
          value: {
            answered: 1, nova: 3, myca: 2, vex: 1, echo: 1, lux: 0, rook: 0, aegis: 0, cass: 0,
          },
        },
        {
          name: 'I consider feedback carefully but don’t always act.',
          slug: 'slug-consider-feedback',
          value: {
            answered: 1, cass: 3, aegis: 2, rook: 1, lux: 1, vex: 0, nova: 0, echo: 0, myca: 0,
          },
        },
        {
          name: 'I consult with my team before deciding on changes.',
          slug: 'slug-consult-team',
          value: {
            answered: 1, myca: 3, rook: 1, nova: 0, vex: 0, lux: 0, echo: 0, aegis: 0, cass: 1,
          },
        },
        {
          name: 'I tend to trust my own judgment.',
          slug: 'slug-trust-judgment',
          value: {
            answered: 1, vex: 3, cass: 2, nova: 0, echo: 0, lux: 0, rook: 0, myca: 0, aegis: 1,
          },
        },
        {
          name: 'I balance feedback with my long-term strategy.',
          slug: 'slug-balance-feedback',
          value: {
            answered: 1, lux: 3, nova: 1, rook: 1, echo: 0, vex: 0, aegis: 0, myca: 0, cass: 0,
          },
        },
      ],
    },
    {
      type: 'multiple-choice',
      questionText: 'How do you react under pressure?',
      options: [
        {
          name: 'I thrive under pressure and enjoy challenges.',
          slug: 'slug-thrive',
          value: {
            answered: 1, vex: 3, nova: 1, echo: 2, lux: 0, rook: 0, aegis: 0, myca: 0, cass: 0,
          },
        },
        {
          name: 'I plan ahead to avoid high-pressure situations.',
          slug: 'slug-plan-ahead',
          value: {
            answered: 1, rook: 3, aegis: 2, lux: 1, nova: 0, vex: 0, echo: 0, myca: 0, cass: 2,
          },
        },
        {
          name: 'I stay calm and work through issues methodically.',
          slug: 'slug-stay-calm',
          value: {
            answered: 1, cass: 3, lux: 2, rook: 1, aegis: 1, nova: 0, vex: 0, echo: 0, myca: 0,
          },
        },
        {
          name: 'I seek support from my team to manage stress.',
          slug: 'slug-seek-support',
          value: {
            answered: 1, myca: 3, nova: 1, echo: 0, lux: 0, rook: 0, aegis: 0, vex: 0, cass: 0,
          },
        },
        {
          name: 'I prefer to handle stress by experimenting with solutions.',
          slug: 'slug-experiment',
          value: {
            answered: 1, echo: 3, vex: 1, nova: 1, rook: 0, aegis: 0, lux: 0, myca: 0, cass: 0,
          },
        },
      ],
    },
    {
      type: 'multiple-choice',
      questionText: 'How do you approach team projects?',
      options: [
        {
          name: 'I like to lead and make decisions quickly.',
          slug: 'slug-lead',
          value: {
            answered: 1, vex: 3, nova: 1, lux: 2, echo: 1, rook: 0, aegis: 0, myca: 0, cass: 0,
          },
        },
        {
          name: 'I prefer to plan and structure the project.',
          slug: 'slug-plan',
          value: {
            answered: 1, rook: 3, lux: 1, aegis: 2, nova: 0, vex: 0, echo: 0, myca: 0, cass: 1,
          },
        },
        {
          name: 'I enjoy collaborating and fostering teamwork.',
          slug: 'slug-collaborate',
          value: {
            answered: 1, myca: 3, nova: 1, echo: 1, lux: 0, rook: 0, aegis: 0, vex: 0, cass: 0,
          },
        },
        {
          name: 'I experiment and find creative solutions.',
          slug: 'slug-creative-solutions',
          value: {
            answered: 1, echo: 3, nova: 1, vex: 1, lux: 0, rook: 0, aegis: 0, myca: 0, cass: 0,
          },
        },
        {
          name: 'I prefer to stay in a supportive role, avoiding leadership.',
          slug: 'slug-supportive',
          value: {
            answered: 1, cass: 3, aegis: 2, rook: 1, nova: 0, vex: 0, echo: 0, lux: 0, myca: 0,
          },
        },
      ],
    },
    {
      type: 'multiple-choice',
      questionText: 'How do you handle uncertainty in work or life?',
      options: [
        {
          name: 'I embrace uncertainty and look for new opportunities.',
          slug: 'slug-embrace',
          value: {
            answered: 1, vex: 3, nova: 2, echo: 2, lux: 1, rook: 0, aegis: 0, myca: 0, cass: 0,
          },
        },
        {
          name: 'I cautiously assess risks before moving forward.',
          slug: 'slug-assess-risks',
          value: {
            answered: 1, cass: 3, aegis: 2, rook: 1, lux: 1, vex: 0, nova: 0, echo: 0, myca: 0,
          },
        },
        {
          name: 'I consult with my team to figure out the best approach.',
          slug: 'slug-consult-team',
          value: {
            answered: 1, myca: 3, lux: 1, rook: 0, vex: 0, nova: 0, echo: 0, aegis: 0, cass: 0,
          },
        },
        {
          name: 'I prefer to wait for clarity before making any decisions.',
          slug: 'slug-wait-for-clarity',
          value: {
            answered: 1, rook: 3, aegis: 1, cass: 1, nova: 0, lux: 0, vex: 0, echo: 0, myca: 0,
          },
        },
        {
          name: 'I innovate and experiment to adapt to uncertain situations.',
          slug: 'slug-innovate',
          value: {
            answered: 1, echo: 3, nova: 1, vex: 1, lux: 0, rook: 0, aegis: 0, myca: 0, cass: 0,
          },
        },
      ],
    },
  ],
}
