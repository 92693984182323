import { type QuizRequirement, QuizRequirementSchema } from '#models'

import { LogoMemoryQuizDataSlug, LogoMemoryTaskSlug } from './Slugs.ts'

export const LogoMemoryQuizData: QuizRequirement = {
  // eslint-disable-next-line @stylistic/max-len
  description: "Begin your Dataism Journey — find out if you're a Dataist! Some people may call themselves a tech-lover, and others may say that they're tech-cursed, but no matter your experience or love of technology, you're probably a Dataist!",
  slug: LogoMemoryQuizDataSlug,
  task: LogoMemoryTaskSlug,
  type: 'quiz',
  schema: QuizRequirementSchema,
  questions: [
    {
      type: 'multiple-choice',
      questionText: 'Do you use any of the following social media accounts? (Facebook, Instagram, X (Twitter), Reddit, TikTok)',
      options: [
        {
          name: 'Yes, I use most of these.',
          slug: 'slug-yes-most',
          value: { dataist: 3 },
        },
        {
          name: 'Yes, I use a couple of these.',
          slug: 'slug-yes-couple',
          value: { dataist: 2 },
        },
        {
          name: "No, I don't use any of these.",
          slug: 'slug-no-social',
          value: { dataist: 0 },
        },
      ],
    },
    {
      type: 'multiple-choice',
      questionText: 'Do you have an email address?',
      options: [
        {
          name: 'Yes, I have multiple email accounts.',
          slug: 'slug-multiple-email',
          value: { dataist: 3 },
        },
        {
          name: 'Yes, I have one email account.',
          slug: 'slug-one-email',
          value: { dataist: 2 },
        },
        {
          name: "No, I don't have an email address.",
          slug: 'slug-no-email',
          value: { dataist: 0 },
        },
      ],
    },
    {
      type: 'multiple-choice',
      questionText: 'Do you use cloud storage, such as Google Cloud, iCloud, Dropbox, or Google Drive?',
      options: [
        {
          name: 'Yes, I use multiple cloud storage services.',
          slug: 'slug-multiple-cloud',
          value: { dataist: 3 },
        },
        {
          name: 'Yes, I use one cloud storage service.',
          slug: 'slug-one-cloud',
          value: { dataist: 2 },
        },
        {
          name: "No, I don't use cloud storage.",
          slug: 'slug-no-cloud',
          value: { dataist: 0 },
        },
      ],
    },
    {
      type: 'multiple-choice',
      questionText: 'How often do you use the internet?',
      options: [
        {
          name: 'Multiple times a day',
          slug: 'slug-multiple-daily',
          value: { dataist: 3 },
        },
        {
          name: 'Once a day',
          slug: 'slug-once-daily',
          value: { dataist: 2 },
        },
        {
          name: 'A few times a week',
          slug: 'slug-few-week',
          value: { dataist: 1 },
        },
        {
          name: 'Once in a while',
          slug: 'slug-once-in-while',
          value: { dataist: 1 },
        },
      ],
    },
  ],
}
