import type { ReactElement } from 'react'
import React from 'react'

import { app } from '#firebase'

import { FirebaseAppContext } from './Context.ts'

interface FirebaseAppProviderProps {
  children?: ReactElement[] | ReactElement | undefined
}

export const FirebaseAppProvider: React.FC<FirebaseAppProviderProps> = ({ children }) => {
  return (
    <FirebaseAppContext.Provider value={app}>
      {children}
    </FirebaseAppContext.Provider>
  )
}
