import type { CommonFields } from '@xylabs/pixel'
import type { Mixpanel } from 'mixpanel-browser'

import type { Events } from './DataismEventHandler.ts'
import { DataismEventHandler } from './DataismEventHandler.ts'
import type { DataismEventHandlerInterface } from './DataismEventHandlerInterface.ts'
import { MixpanelEvents } from './Mixpanel/index.ts'

export class MixpanelEventHandler<T extends CommonFields = CommonFields> extends DataismEventHandler<T> implements DataismEventHandlerInterface<T> {
  protected mixpanelEvents: MixpanelEvents

  constructor(protected mixpanel: Mixpanel) {
    super()
    this.mixpanelEvents = new MixpanelEvents(mixpanel)
  }

  override get events() {
    return this.mixpanelEvents as Events<T>
  }
}
