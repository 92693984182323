import type { Auth } from '@firebase/auth'
import { signOut } from '@firebase/auth'

import { getDataismUserEvents } from '#user-events'

/**
 * Signs out the current user. Idempotent so it can be called
 * multiple times and even if no user is currently signed in.
 * @param auth The Auth context to use
 */
export const logout = async (auth: Auth) => {
  if (auth.currentUser) {
    const userId = auth.currentUser.uid
    const email = auth.currentUser.email ?? undefined
    const thirdParty = auth.currentUser.providerId
    await getDataismUserEvents().accountLogout({
      userId, email, thirdParty,
    })
  }
  await signOut(auth)
}
