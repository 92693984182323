import type {
  CollectionReference, DocumentData, Query, WhereFilterOp,
} from '@firebase/firestore'
import {
  limit,
  orderBy,
  query,
  where,
} from '@firebase/firestore'

import type { IQueryBuilder } from '../../interface/index.ts'

export class FirestoreQueryBuilder<TData extends DocumentData = DocumentData> implements IQueryBuilder<TData, Query> {
  private query: Query

  constructor(collection: CollectionReference) {
    this.query = query(collection)
  }

  build(): Query {
    return this.query
  }

  limit(count: number): this {
    this.query = query(this.query, limit(count))
    return this
  }

  offset(_count: number): this {
    // TODO: Do not read all data back into memory ($$$), instead use something lighter like a cursor if possible
    // https://firebase.google.com/docs/firestore/query-data/query-cursors
    // Firestore doesn't support offset directly, so you would typically chain a startAfter with limit instead.
    throw new Error('Not implemented')
  }

  orderBy(field: keyof TData, direction: 'asc' | 'desc' = 'asc'): this {
    this.query = query(this.query, orderBy(field as string, direction))
    return this
  }

  where<K extends keyof TData>(field: K, operator: WhereFilterOp, value: TData[K]): this {
    this.query = query(this.query, where(field as string, operator, value))
    return this
  }
}
