import { type QuizTaskValidationResult, QuizTaskValidationResultSchema } from '../../../../models/user/index.ts'
import {
  GeneralInternetQuizData, GeneralInternetQuizSlug, GeneralInternetQuizValidationSlug,
  GenericTaskSlug,
} from '../../../journey/index.ts'

export const SampleQuizTaskValidationResult: QuizTaskValidationResult = {
  startTime: Date.now(),
  endTime: Date.now() + 10_000,
  journeyId: 'slug-sample-journey',
  schema: QuizTaskValidationResultSchema,
  userId: '1',
  taskId: GenericTaskSlug,
  timestamp: Date.now(),
  results: [GeneralInternetQuizData.questions[0].options[1]],
  validatedRequirements: {
    requirementId: GeneralInternetQuizSlug,
    taskValidationId: GeneralInternetQuizValidationSlug,
  },
}
