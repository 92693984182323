import { RedirectWithQuery } from '@xylabs/react-common'
import React from 'react'
import type { RoutesProps } from 'react-router-dom'
import { Route, Routes } from 'react-router-dom'

import { AccountRoutes } from './account/index.ts'
import { BlogRoutes } from './blog/index.ts'
import { DeleteDataRoutes } from './delete-data/index.ts'
import { EarlyAccessRoutes } from './early-access/index.ts'
import { HomeRoutes } from './home/index.ts'
import { InfoRoutes } from './info/index.ts'
import { MessagingRoutes } from './messaging/index.ts'
import { MissionRoutes } from './mission/index.ts'
import { PeopleRoutes } from './people/index.ts'
import { PersonaRoutes } from './personas/index.ts'
import { PillarsRoutes } from './pillars/index.ts'
import { PrivacyRoutes } from './privacy/index.ts'
import { Four04Page, ResourcesRoutes } from './resources/index.ts'
import { StartLazyRoutes } from './start/index.ts'
import { ToolsRoutes } from './tools/index.ts'

export const SiteRoutes: React.FC<RoutesProps> = (props) => {
  return (
    <Routes {...props}>
      <Route path="/*" element={<HomeRoutes />} />
      <Route path="/account/*" element={<AccountRoutes />} />
      <Route path="/privacy/*" element={<PrivacyRoutes />} />
      <Route path="/request-early-access/*" element={<EarlyAccessRoutes />} />
      <Route path="/delete-data/*" element={<DeleteDataRoutes />} />
      <Route path="/info/*" element={<InfoRoutes />} />
      <Route path="/messaging/*" element={<MessagingRoutes />} />
      <Route path="/mission/*" element={<MissionRoutes />} />
      <Route path="/people/*" element={<PeopleRoutes />} />
      <Route path="/persona/*" element={<PersonaRoutes />} />
      <Route path="/pillars/*" element={<PillarsRoutes />} />
      <Route path="/resources/*" element={<ResourcesRoutes />} />
      <Route path="/tools/*" element={<ToolsRoutes />} />
      <Route path="/blog/*" element={<BlogRoutes />} />
      <Route path="/start/*" element={<StartLazyRoutes />} />
      <Route path="/privacy/*" element={<PrivacyRoutes />} />
      <Route path="/terms/*" element={<RedirectWithQuery href="https://xylabs.com/terms/" />} />
      <Route path="/__/*">
        {/* This allows the google SSO static files to be accessed directly */}
      </Route>
      <Route path="*" element={<Four04Page />} />
    </Routes>
  )
}
