import { LaunchRounded } from '@mui/icons-material'
import type { SxProps } from '@mui/material'
import {
  Box,
  Container, Grid2, Stack, Typography, useTheme,
} from '@mui/material'
import type { ButtonExProps } from '@xylabs/react-button'
import type { FlexBoxProps } from '@xylabs/react-flexbox'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import type { ReactNode } from 'react'
import React from 'react'

import { AppBarOffset } from '../AppBarOffset.tsx'
import { ButtonSection } from './ButtonSection.tsx'

export interface DataismBasicHeroProps extends FlexBoxProps {
  backgroundImage?: string
  button1Href?: string
  button1Props?: Omit<ButtonExProps, 'to' | 'href' | 'toOptions'>
  button1Text?: string
  button1To?: string
  button2Href?: string
  button2Props?: Omit<ButtonExProps, 'to' | 'href' | 'toOptions'>
  button2Text?: string
  button2To?: string
  desc: string
  extras?: ReactNode
  gradientTitle?: string
  heroComponent?: ReactNode
  heroImage?: string
  heroImageSxProps?: SxProps
  subLinkSection?: ReactNode
  sx?: SxProps
  title: string
  title2?: string
}

export const DataismBasicHero: React.FC<DataismBasicHeroProps> = ({
  title,
  desc,
  heroComponent,
  heroImage,
  button1Text,
  button2Text,
  button2To,
  button1To,
  button2Href,
  button1Href,
  button1Props,
  button2Props,
  extras,
  subLinkSection,
  heroImageSxProps,
  sx,
  ...props
}) => {
  const theme = useTheme()

  const button1FinalProps = {
    ...button1Props,
    buttonText: button1Text,
    onClick: () => {
      if (button1To?.startsWith('#')) {
        const section = document.querySelector(button1To)
        section?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    },
  }

  const button2FinalProps = {
    ...button2Props,
    buttonText: button2Text,
    onClick: () => {
      if (button2To?.startsWith('#')) {
        const section = document.querySelector(button2To)
        section?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    },
  }

  return (
    <Box>
      <FlexGrowCol
        position="relative"
        sx={{
          backgroundPosition: {
            lg: 'center left', md: 'center left', xs: 'center left',
          },
          backgroundSize: { md: 'cover', xs: '100%' },
          justifyContent: 'flex-start',
          overflow: 'hidden',
          paddingTop: { md: theme.spacing(0), xs: theme.spacing(2) },
          ...sx,
        }}
        style={{ backgroundRepeat: 'no-repeat' }}
        {...props}
      >
        <AppBarOffset />
        <Container maxWidth="xl" sx={{ overflow: 'visible' }}>
          <Grid2
            container
            sx={{
              overflow: 'visible',
              alignItems: { xs: 'stretch' },
              justifyContent: { md: 'flex-start', xs: 'center' },
              minHeight: { xs: '400px' },
            }}
          >
            <Grid2
              size={{
                xs: 12, md: 6, sm: 8,
              }}
              zIndex={30}
            >
              <FlexGrowCol
                paddingY={3}
                sx={{
                  alignItems: { md: 'flex-start', xs: 'center' },
                  marginTop: { md: 3, xs: 2 },
                }}
              >
                <Typography variant="h1" gutterBottom sx={{ textAlign: { md: 'left', xs: 'center' } }}>
                  {title
                    ? <span>{`${title} `}</span>
                    : null}
                </Typography>
                <Typography variant="body1" component="h2" gutterBottom sx={{ textAlign: { md: 'left', xs: 'center' } }}>
                  {desc}
                </Typography>
                <Stack sx={{ flexDirection: { md: 'row', xs: 'column' } }} gap={1} marginTop={1}>
                  {button1Href
                    ? (
                        <ButtonSection
                          endIcon={<LaunchRounded />}
                          href={button1Href}
                          {...button1FinalProps}
                        />
                      )
                    : button1To
                      ? (
                          <ButtonSection
                            to={button1To}
                            {...button1FinalProps}
                          />
                        )
                      : (
                          <ButtonSection
                            {...button1FinalProps}
                          />
                        )}
                  {button2Href
                    ? (
                        <ButtonSection
                          endIcon={<LaunchRounded />}
                          variant="outlined"
                          href={button2Href}
                          {...button2FinalProps}
                        />
                      )
                    : button2To
                      ? (
                          <ButtonSection
                            variant="outlined"
                            to={button2To}
                            {...button2FinalProps}
                          />
                        )
                      : (
                          <ButtonSection
                            variant="outlined"
                            {...button2FinalProps}
                          />
                        )}
                </Stack>
                {subLinkSection}
                {extras}
              </FlexGrowCol>
            </Grid2>
            <Grid2
              size={{ xs: 12, md: 6 }}
              alignItems="center"
            >
              <FlexGrowCol
                alignItems="center"
                height="100%"
              >
                {heroComponent}
              </FlexGrowCol>
            </Grid2>
          </Grid2>
          {heroImage
            ? (
                <FlexGrowCol
                  position="absolute"
                  right={-70}
                  bottom={-20}
                  alignItems="center"
                  sx={{
                    backgroundImage: { md: `url(${heroImage})`, xs: 'none' },
                    backgroundPosition: { lg: 'bottom left', md: 'bottom left' },
                    backgroundSize: { xs: '120%', lg: '120%' },
                    backgroundRepeat: 'no-repeat',
                    overflow: 'visible',
                    width: '45vw',
                    minHeight: {
                      md: '500px',
                      sm: '500px',
                      xs: '400px',
                    },
                    display: { md: 'flex', xs: 'none' },
                    ...heroImageSxProps,
                  }}
                >
                </FlexGrowCol>
              )
            : null}
        </Container>
      </FlexGrowCol>
    </Box>
  )
}
