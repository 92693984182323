import type { QuizTaskValidation, Slug } from '#models'

import { PillarsOfDataismQuizData } from './PillarsOfDataismQuizData.ts'

export const PillarsOfDataismValidationSlug: Slug = 'slug-pillars-of-dataism-quiz-validation'

export const PillarsOfDataismValidation: QuizTaskValidation = {
  outcomes: [{ thresholds: { min: { correct: PillarsOfDataismQuizData.questions.length } } }],
  type: 'quiz',
  schema: 'org.dataism.journey.task.validation.quiz',
  slug: PillarsOfDataismValidationSlug,
}
