import { type JourneyTask, JourneyTaskSchema } from '#models'

import { GeneralInternetQuizBadge } from './GeneralInternetQuizBadge.ts'
import { GeneralInternetQuizData } from './GeneralInternetQuizData.ts'
import { GeneralInternetQuizDatum } from './GeneralInternetQuizDatum.ts'
import { GeneralInternetQuizValidation } from './GeneralInternetQuizValidation.ts'
import { GenericTaskSlug } from './Slug.ts'

export const GenericTaskData: JourneyTask = {
  description: 'Generic Task Description',
  metadata: ['quiz'],
  resource: [
    {
      title: 'Google.com',
      url: 'https://www.google.com',
      schema: 'org.dataism.journey.task.resource',
      slug: 'slug-google-url',
    },
  ],
  schema: JourneyTaskSchema,
  slug: GenericTaskSlug,
  timeToComplete: (60 * 2) * 1000,
  title: 'Generic Task Title',
  taskSet: {
    reward: [GeneralInternetQuizBadge, GeneralInternetQuizDatum],
    validatedRequirements: [{ requirement: GeneralInternetQuizData, validation: GeneralInternetQuizValidation }],
  },
}
